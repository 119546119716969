import React from 'react';

import { useSnackbar, SnackbarMessage, SnackbarKey } from 'notistack';

// eslint-disable-next-line import/no-cycle
import CustomSnackbar, { SnackBarType } from 'common/components/snackbar/custom-snackbar';

export interface IToastDetails {
  title: string;
  desc: string;
  link?: {
    title: string;
    url: string;
  };
}

interface UseToasterReturnType {
  showToaster: (message: SnackbarMessage, details?: IToastDetails, type?: SnackBarType) => void;
}

function useToaster(): UseToasterReturnType {
  const { enqueueSnackbar } = useSnackbar();

  const showToaster = (
    messagestring: SnackbarMessage,
    details?: IToastDetails,
    type?: SnackBarType,
  ): void => {
    enqueueSnackbar(messagestring, {
      content: (key: SnackbarKey, message: SnackbarMessage): JSX.Element => {
        return (
          <CustomSnackbar
            id={key}
            message={message}
            details={details}
            type={type ?? SnackBarType.success}
          />
        );
      },
    });
  };

  return {
    showToaster,
  };
}

export default useToaster;
