/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import * as React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  Menu,
  MenuItem,
  Avatar,
  Button,
  ListItemIcon,
  ListItemText,
  Box,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoutIcon } from 'assets/images/tabler-icon/logout.svg';
import { ReactComponent as PersonIcon } from 'assets/images/tabler-icon/single-user.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import ROUTES from 'constants/routes';
import { useAppSelector } from 'store/hooks';
import { getCurrentLoggedInUserDetails } from 'store/reducers/userSlice';
import { remCalc } from 'theme/utils';

function AppProfileInfo(): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user, logout } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userDetails = useAppSelector(getCurrentLoggedInUserDetails);

  const inviteCount = React.useMemo(() => {
    if (userDetails) {
      return userDetails?.invites.length;
    }
    return 0;
  }, [userDetails]);

  const { given_name = '', picture = '' } = user ?? {};

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleNavigation = (): void => {
    navigate(ROUTES.USER.PROFILE);
    handleClose();
  };
  return (
    <>
      <Button
        onClick={handleClick}
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '32px',
          height: '32px',
          minWidth: 'unset',
          p: 0,
          ml: '8px',
          position: 'relative',
        }}
      >
        <Avatar
          alt={given_name}
          src={picture}
          sx={{ borderRadius: '6px', height: '100%', width: '100%' }}
        />
        {!!inviteCount && (
          <Box
            sx={{
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: '-7px',
              padding: '5px',
              background: 'red',
              height: '20px',
              width: '20px',
              color: '#fff',
              right: '-9px',
            }}
          >
            {inviteCount}
          </Box>
        )}
      </Button>
      <Menu
        id='profile-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 148,
        }}
        PaperProps={{
          sx: {
            boxShadow:
              '0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 3px 6px -3px rgba(23, 24, 24, 0.08)',
            border: '1px solid #E5E5E5',
            borderRadius: '4px',
            minWidth: '150px',
          },
        }}
      >
        <MenuItem onClick={handleNavigation} sx={{ p: '12px' }}>
          <ListItemIcon sx={{ minWidth: '28px' }}>
            <SVGIcon icon={PersonIcon} fontSize='small' />
          </ListItemIcon>
          <ListItemText sx={{ color: '#141414' }}>
            <Stack direction='row' gap='5px'>
              {t('profile_details')}
              {!!inviteCount && (
                <Box
                  sx={{
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px',
                    background: 'red',
                    height: '20px',
                    width: '20px',
                    color: '#fff',
                  }}
                >
                  {inviteCount}
                </Box>
              )}
            </Stack>
          </ListItemText>
        </MenuItem>
        <MenuItem
          sx={{ p: '12px' }}
          onClick={(): void => {
            localStorage.removeItem('orgId');
            logout({ returnTo: window.location.origin });
          }}
        >
          <ListItemIcon sx={{ minWidth: '28px' }}>
            <SVGIcon icon={LogoutIcon} fontSize='small' />
          </ListItemIcon>
          <ListItemText sx={{ color: '#141414', fontSize: remCalc(14) }}>
            {t('logout')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default AppProfileInfo;
