import { TypographyOptions } from '@mui/material/styles/createTypography';

import { remCalc } from 'theme/utils';

const TypographyOverRides: TypographyOptions = {
  fontSize: 12,
  fontFamily: '"Inter", sans-serif',
  htmlFontSize: 14,
  h1: {
    fontSize: '3rem',
    fontWeight: 800,
  },
  h2: {
    fontSize: '2.8rem',
    fontWeight: 600,
  },
  h3: {
    fontSize: '2.5rem',
    fontWeight: 500,
  },
  h4: {
    fontSize: '1.875rem',
    fontWeight: 500,
  },
  h5: {
    fontSize: '1.5rem',
  },
  h6: {
    fontSize: '1rem',
  },
  subtitle1: {
    fontSize: '1rem',
  },
  subtitle2: {
    fontSize: remCalc(12),
    color: '#424242',
    fontWeight: 400,
  },
};

export default TypographyOverRides;
