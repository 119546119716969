import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomInput from 'common/components/custom-input/custom-input';
import { remCalc } from 'theme/utils';

import validation from './validation';

export interface IManualAssetScannerProps {
  onChange: (id: string) => void;
  handleQrScannerLink: () => void;
}

interface IManualAsset {
  assetId: string | null;
}

function ManualAssetScanner({
  onChange,
  handleQrScannerLink,
}: IManualAssetScannerProps): JSX.Element {
  const { t } = useTranslation();

  const methods = useForm<IManualAsset>({
    defaultValues: {
      assetId: null,
    },
    resolver: yupResolver(validation),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleForm = ({ assetId }: IManualAsset): void => {
    if (assetId) {
      onChange(assetId);
    }
  };

  const onSubmit = handleSubmit((data) => handleForm(data as IManualAsset));

  return (
    <Stack component='form' sx={{ padding: '16px' }} onSubmit={onSubmit}>
      <CustomInput
        label={t('common.external_id', { ns: 'translation' })}
        customError={errors.assetId}
        control={control}
        name='assetId'
        className='mobile'
        translation={false}
      />
      <Box sx={{ mt: { xs: 'auto', md: 'unset' } }}>
        <Button type='submit' className='mobile' variant='contained'>
          {t('common.submit')}
        </Button>
      </Box>
      <Typography
        sx={{
          fontSize: remCalc(14),
          fontWeight: 400,
          lineHeight: '20px',
          cursor: 'pointer',
          textAlign: 'center',
          mt: '24px',
          color: '#0A78C1',
        }}
        onClick={handleQrScannerLink}
      >
        {t('common.scan_qr')}
      </Typography>
    </Stack>
  );
}

export default ManualAssetScanner;
