import { makeStyles } from 'tss-react/mui';

// eslint-disable-next-line import/no-cycle
import { SnackBarType } from './custom-snackbar';

const useStyles = makeStyles<{ type: SnackBarType }>()((theme) => ({
  root: {
    padding: '16px',
    borderRadius: '6px',
    position: 'relative',
    width: '440px',
    [theme.breakpoints.down('md')]: {
      minWidth: '300px',
    },
    background: '#fff',
    alignItems: 'flex-start',
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    '& .toast-close-icon': {
      right: 16,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
}));

export default useStyles;
