/* eslint-disable new-cap */
/* eslint-disable import/prefer-default-export */
import { isAfter, isBefore } from 'date-fns';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';

import { IFormInputType } from 'common/components/form-builder/type';
import {
  IAdditionalFieldsList,
  IAdditionalFieldsType,
  IAddJobActivityFieldsList,
} from 'services/settings-service/settings.modal';

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'phoneNumber', function (errorMessage) {
  // eslint-disable-next-line func-names
  return this.test('test-phone-length', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return true;
    }

    return isValidPhoneNumber(value ?? '') || createError({ path, message: errorMessage });
  });
});

export const email = yup
  .string()
  .email('validation.enter_valid')
  .required('validation.enter_valid');
export const positiveNumber = yup
  .number()
  .typeError('validation.enter_valid')
  .positive('validation.enter_valid')
  .min(1, 'validation.enter_valid')
  .required('validation.enter_valid')
  .nullable();
export const required = yup.string().required('validation.enter_valid');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const phoneNumber = yup.string().phoneNumber('validation.enter_valid').nullable();
export const dateFuture = yup
  .date()
  .typeError('validation.enter_valid')
  .required('validation.enter_valid')
  .test('future_date_test', 'validation.future_date', (value) => {
    if (value) {
      const result = isAfter(value, new Date());
      return result;
    }
    return false;
  });
export const datePast = yup
  .date()
  .nullable()
  .test('future_date_test', 'validation.past_date', (value) => {
    if (value) {
      const result = isBefore(value, new Date());
      return result;
    }
    return true;
  });
export const arrayRequired = yup.array().min(1, 'validation.enter_valid');
export const objectRequired = yup.object().nullable().required('validation.enter_valid');
export const numberRequired = yup
  .number()
  .typeError('validation.valid_number')
  .required('validation.enter_valid');
export const numberTypeCheck = yup.number().typeError('validation.valid_number');

export const numberRequiredDropdown = yup.number().nullable().required('validation.enter_valid');
export const noSpecialChar = yup
  .string()
  .required('validation.enter_valid')
  .matches(/^[a-zA-Z0-9 ]*$/, 'enter name without special char');

export const addressValidation = yup.object().shape({
  latitude: numberRequired,
  longitude: numberRequired,
});

export const additionalFieldsLazyValidation = (
  extraFields: IAdditionalFieldsList[],
): Lazy<yup.AnySchema> => {
  return yup.lazy(() => {
    const shapes: Record<string, yup.AnySchema> = {};
    extraFields.forEach(({ name, required: requiredStatus, type }) => {
      if (requiredStatus) {
        if (type === IAdditionalFieldsType.multi_select) {
          shapes[name] = arrayRequired.required('validation.enter_valid');
        } else if (type === IAdditionalFieldsType.number) {
          shapes[name] = numberRequired;
        } else if (type === IAdditionalFieldsType.attachment) {
          shapes[name] = yup
            .array()
            .transform((_, originalValue) => {
              return originalValue === undefined ? [] : originalValue;
            })
            .min(1, 'validation.enter_valid')
            .required('validation.enter_valid');
        } else {
          shapes[name] = required;
        }
      } else if (type === IAdditionalFieldsType.number) {
        shapes[name] = yup
          .number()
          .transform((value, originalValue) => {
            return originalValue === '' ? null : Number(String(originalValue).replace(/,/g, ''));
          })
          .typeError('validation.valid_number')
          .nullable();
      }
    });
    return yup.object().shape(shapes);
  });
};

export const dynamicFieldsLazyValidation = (
  extraFields: IAddJobActivityFieldsList[],
): Lazy<yup.AnySchema> => {
  return yup.lazy(() => {
    const shapes: Record<string, yup.AnySchema> = {};
    extraFields.forEach(({ fieldId, required: requiredStatus, inputType }) => {
      if (requiredStatus) {
        if (inputType === IFormInputType.MultiSelect || inputType === IFormInputType.Checkbox) {
          shapes[fieldId] = arrayRequired;
        } else if (inputType === IFormInputType.NumberInput) {
          shapes[fieldId] = numberRequired;
        } else if (inputType === IFormInputType.Date || inputType === IFormInputType.DateTime) {
          shapes[fieldId] = required.nullable();
        } else if (inputType === IFormInputType.Attachment) {
          shapes[fieldId] = yup
            .array()
            .transform((_, originalValue) => (!originalValue ? [] : originalValue))
            .min(1, 'validation.enter_valid');
        } else {
          shapes[fieldId] = required.nullable();
        }
      } else if (inputType === IFormInputType.NumberInput) {
        shapes[fieldId] = yup
          .number()
          .transform((value, originalValue) => {
            return originalValue === '' ? null : Number(String(originalValue).replace(/,/g, ''));
          })
          .typeError('validation.valid_number')
          .nullable();
      }
    });
    return yup.object().shape(shapes);
  });
};
