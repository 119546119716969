import * as React from 'react';
import { ComponentType, SVGProps } from 'react';

import { Stack, Typography, Box } from '@mui/material';
import { SnackbarKey, SnackbarMessage, SnackbarContent } from 'notistack';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/images/tabler-icon/close.svg';
import { ReactComponent as TickIcon } from 'assets/images/tabler-icon/tick.svg';
import { ReactComponent as WarningIcon } from 'assets/images/tabler-icon/warning-circle.svg';
import SnackbarAction from 'common/components/snackbar/snackbar-action';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
// eslint-disable-next-line import/no-cycle
import { IToastDetails } from 'hooks/useToaster';
import { remCalc } from 'theme/utils';

// eslint-disable-next-line import/no-cycle
import useStyle from './style';

interface CustomSnackBarProps {
  id: SnackbarKey;
  message: SnackbarMessage;
  type?: SnackBarType;
  details?: IToastDetails;
}

export enum SnackBarType {
  error = 'error',
  success = 'success',
  warning = 'warning',
}

const CustomSnackBar = React.forwardRef<HTMLDivElement, CustomSnackBarProps>((props, ref) => {
  const { id, message, type = SnackBarType.success, details, ...other } = props;
  const { classes } = useStyle({ type });
  const { title = '', desc = '', link = '' } = details ?? {};

  const getIcon = (): ComponentType<SVGProps<SVGSVGElement>> => {
    switch (type) {
      case SnackBarType.error:
        return CloseIcon;

      case SnackBarType.warning:
        return WarningIcon;

      default:
        return TickIcon;
    }
  };

  const getColor = (): string => {
    switch (type) {
      case SnackBarType.error:
        return '#DF2D2D';

      case SnackBarType.warning:
        return '#F79009';

      default:
        return '#32D583';
    }
  };

  return (
    <SnackbarContent
      ref={ref}
      role='alert'
      {...other}
      style={{
        boxShadow:
          '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      }}
    >
      <Stack direction='row' spacing={2} className={classes.root}>
        <Box
          sx={{
            width: '20px',
            height: '20px',
            borderRadius: '2px',
            background: getColor(),
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SVGIcon icon={getIcon()} width='18px' height='18px' stroke='#fff' />
        </Box>
        <Stack>
          <Typography
            sx={{
              fontSize: remCalc(14),
              fontWeight: type === SnackBarType.error ? 400 : 600,
              lineHeight: '20px',
              whiteSpace: 'break-spaces',
              paddingRight: '18px',
              '&:first-letter': {
                textTransform: 'capitalize',
              },
            }}
          >
            {title || message}
          </Typography>
          {desc && (
            <Typography
              sx={{
                fontSize: remCalc(14),
                fontWeight: 400,
                lineHeight: '20px',
                color: '#737373',
                mt: '4px',
              }}
            >
              {desc}
            </Typography>
          )}
          {link && (
            <Typography
              component={Link}
              sx={{
                fontSize: remCalc(14),
                fontWeight: 600,
                lineHeight: '20px',
                color: '#0A78C1',
                mt: '10px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              to={link.url}
            >
              {link.title}
            </Typography>
          )}
        </Stack>
        <SnackbarAction id={id} />
      </Stack>
    </SnackbarContent>
  );
});

CustomSnackBar.defaultProps = {
  type: SnackBarType.success,
  details: undefined,
};

export default CustomSnackBar;
