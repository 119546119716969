import { ICustomMeta } from 'services/common/common.modal';

export enum IRefetchActionType {
  delete = 'delete',
  create = 'create',
}

export interface IUseAPIReturnType<T> {
  error: unknown;
  response: T | null;
  loading: boolean;
  meta: ICustomMeta | null;
  refetchApi?: (action: IRefetchActionType, defaultQuery?: Record<string, unknown>) => void;
}

export interface IApiInterface {
  [key: string]: {
    [key1: string]: string;
  };
}

export interface ExtraOption {
  urlId?: string;
  replaceId?: boolean;
  isDisabled?: boolean;
  syncWithQuery?: boolean;
  defaultQuery?: Record<string, unknown>;
  customData?: {
    customPaginationHandler?: (page: string) => void;
  };
}
