import { GridColDef, GridColumnGroupingModel, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { parsePhoneNumber } from 'libphonenumber-js';
import { TFunction } from 'react-i18next';

import { IUserDetailsResponse, UserType } from 'services/settings-service/settings.modal';

const headerDetails = [
  { label: 'name', cellSx: { pl: 2 } },
  { label: 'email' },
  { label: 'mobile' },
  { label: 'type' },
];

export const getUserTypeLabel = (userType: UserType): string => {
  switch (userType) {
    case UserType.operator:
      return 'users.operator';
    case UserType.user:
      return 'users.user';

    default:
      return '-';
  }
};

export const columnGroupingModels = (additionalFields: GridColDef[]): GridColumnGroupingModel => {
  return [
    {
      freeReordering: true,
      groupId: 'user_details',
      headerName: 'User Details',
      children: [{ field: 'name' }, { field: 'email' }, { field: 'mobile' }, { field: 'type' }],
    },
    {
      freeReordering: true,
      groupId: 'additional_fields',
      headerName: 'Additional Fields',
      children: additionalFields,
    },
  ];
};

export const getColumns = (additionalFields: GridColDef[], t: TFunction): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'name',
      valueGetter: (_, row: IUserDetailsResponse): string => {
        return `${row.name || ''}`;
      },
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
    },

    {
      field: 'email',
      headerName: 'email',
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: 'mobile',
      headerName: 'mobile',
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params: GridRenderCellParams<IUserDetailsResponse>): React.ReactNode => {
        return params.row?.phone ? parsePhoneNumber(params.row.phone).formatInternational() : '-';
      },
    },
    {
      field: 'type',
      headerName: 'type',
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
      valueGetter: (_, row: IUserDetailsResponse): string => {
        return row?.userType ? t(getUserTypeLabel(row?.userType)) : '-';
      },
    },
    ...additionalFields,
  ];
};

export default headerDetails;
