/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IAdditionalFieldsEntity,
  IAdditionalFieldsList,
} from 'services/settings-service/settings.modal';

import { RootState } from '../store';

type AdditionalFieldsDetails = Record<IAdditionalFieldsEntity, IAdditionalFieldsList[] | null>;

interface AdditionalFieldsSliceState {
  details: AdditionalFieldsDetails;
}

const initialState: AdditionalFieldsSliceState = {
  details: {
    [IAdditionalFieldsEntity.asset]: null,
    [IAdditionalFieldsEntity.job]: null,
    [IAdditionalFieldsEntity.organisation_location]: null,
    [IAdditionalFieldsEntity.part]: null,
    [IAdditionalFieldsEntity.user]: null,
  },
};

export const additionalFieldsSlice = createSlice({
  name: 'additionalFields',
  initialState,
  reducers: {
    setAdditionalFields: (state, action: PayloadAction<AdditionalFieldsDetails>) => {
      state.details = {
        ...state.details,
        ...action.payload,
      };
    },
  },
});

export const { setAdditionalFields } = additionalFieldsSlice.actions;

export const selectAdditionalFields = (module: IAdditionalFieldsEntity) =>
  // eslint-disable-next-line func-names
  function (state: RootState): IAdditionalFieldsList[] | null {
    if (module) {
      return state.additionalFields.details[module];
    }
    return [];
  };

export default additionalFieldsSlice.reducer;
