import React from 'react';

import {
  Chip,
  lighten,
  Box,
  Grow,
  Popper,
  ClickAwayListener,
  Paper,
  Stack,
  MenuList,
} from '@mui/material';

import { ReactComponent as ArrowDown } from 'assets/images/tabler-icon/chevron-down.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { AssetStatus } from 'services/asset-service/asset.modal';
import { remCalc } from 'theme/utils';

// eslint-disable-next-line import/no-cycle
import { getStatusLabel, statusColorMapping, statusOptions } from './config';

interface IAssetStatusBadgeProps {
  status: AssetStatus;
  onDropdown?: () => void | undefined;
}
interface IAssetStatusIconProps {
  status: AssetStatus;
}

interface IAssetDropDownProps extends IAssetStatusIconProps {
  onChange: (status: AssetStatus) => void;
  disabled?: boolean;
}

function AssetStatusBadge({ status, onDropdown }: IAssetStatusBadgeProps): JSX.Element {
  const color = statusColorMapping[status] || '#000';
  const statusLabel = getStatusLabel(status);

  return (
    <Chip
      icon={
        <Box
          sx={{ width: '8px', height: '8px', borderRadius: '50%', background: color, mr: '4px' }}
        />
      }
      deleteIcon={<SVGIcon icon={ArrowDown} stroke={color} width='20px' height='20px' />}
      label={statusLabel}
      onDelete={onDropdown}
      sx={{
        background: lighten(color, 0.85),
        color,
        px: '6px',
        fontSize: remCalc(12),
        fontWeight: 500,
        cursor: onDropdown ? 'pointer' : 'auto',
      }}
    />
  );
}

AssetStatusBadge.defaultProps = {
  onDropdown: null,
};

export function AssetStatusIcon({ status }: IAssetStatusIconProps): JSX.Element {
  const color = statusColorMapping[status];
  return (
    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', background: color, mr: '4px' }} />
  );
}

export function AssetStatusDropdown({
  status,
  onChange,
  disabled,
}: IAssetDropDownProps): JSX.Element | null {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = (): void => {
    if (!disabled) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event: Event | React.SyntheticEvent): void => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const handleSelect = (event: Event | React.SyntheticEvent, newStatus: AssetStatus): void => {
    onChange(newStatus);
    handleClose(event);
  };

  if (!status) {
    return null;
  }

  return (
    <div>
      <Box ref={anchorRef} onClick={handleToggle} sx={{ cursor: 'pointer' }}>
        <AssetStatusBadge status={status} onDropdown={disabled ? undefined : handleToggle} />
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
        sx={{ zIndex: 9 }}
      >
        {({ TransitionProps, placement }): JSX.Element => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                  sx={{ pb: 0, pt: '4px' }}
                >
                  {statusOptions.map(({ label, icon, value }) => {
                    return (
                      <Stack
                        key={value}
                        onClick={(event): void => handleSelect(event, value)}
                        alignItems='center'
                        flexDirection='row'
                        sx={{ display: 'flex', p: '10px 14px', gap: '8px', cursor: 'pointer' }}
                      >
                        {icon} {label}
                      </Stack>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

AssetStatusDropdown.defaultProps = {
  disabled: false,
};

export default AssetStatusBadge;
