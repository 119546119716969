import { JobStatus } from 'services/job-service/job.modal';

// eslint-disable-next-line import/no-cycle
import { JobStatusIcon } from './JobStatusBadge';

export const statusColorMapping = {
  [JobStatus.inprogress]: '#F79009',
  [JobStatus.cancel]: '#F04438',
  [JobStatus.closed]: '#17B26A',
  [JobStatus.open]: '#0A78C1',
};

export const statusOptions = [
  {
    value: JobStatus.inprogress,
    label: 'In Progress',
    icon: <JobStatusIcon status={JobStatus.inprogress} />,
  },
  {
    value: JobStatus.closed,
    label: 'Completed',
    icon: <JobStatusIcon status={JobStatus.closed} />,
  },
  {
    value: JobStatus.open,
    label: 'Open',
    icon: <JobStatusIcon status={JobStatus.open} />,
  },
  {
    value: JobStatus.cancel,
    label: 'Cancel',
    icon: <JobStatusIcon status={JobStatus.cancel} />,
  },
];

export const getStatusLabel = (status: JobStatus): string =>
  statusOptions.find(({ value }) => value === status)?.label ?? '-';
