import React from 'react';

import { Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as Down } from 'assets/images/tabler-icon/chevron-down.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { useAppSelector } from 'store/hooks';
import { getCurrentLoggedInUserDetails } from 'store/reducers/userSlice';
import { remCalc } from 'theme/utils';

function OrgSelector(): JSX.Element {
  const userDetails = useAppSelector(getCurrentLoggedInUserDetails);
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const isSingleOrg = (userDetails?.linkedOrganisations ?? []).length === 1;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (!isSingleOrg) {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: mobileMatches ? '#000' : '#fff' }}
      >
        <span
          style={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {userDetails?.organisation?.name}
        </span>

        {!isSingleOrg && (
          <SVGIcon
            icon={Down}
            fontSize='small'
            stroke={mobileMatches ? '#000' : '#fff'}
            style={{ marginLeft: '4px' }}
          />
        )}
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {userDetails?.linkedOrganisations.map(({ name, id }) => {
          return (
            <MenuItem
              value={id}
              sx={{
                p: '8px 12px',
                border: 'none',
                fontSize: remCalc(14),
                lineHeight: '20px',
                color: '#141414',
                maxWidth: '200px',
              }}
              onClick={(): void => {
                localStorage.setItem('orgId', id);
                window.location.reload();
              }}
            >
              <span
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </span>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default OrgSelector;
