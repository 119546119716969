import React, { useCallback, useEffect } from 'react';

import { Box, Typography, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Alert } from 'assets/images/common/alert.svg';
import { ReactComponent as LoaderIcon } from 'assets/images/tabler-icon/loader.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { AssetStatusIcon } from 'components/assets/asset-status-badge/AssetStatusBadge';
import { getStatusLabel } from 'components/jobs/job-status-badge/config';
import { APIQueryConstant } from 'constants/api.constants';
import { getJobType } from 'features/jobs/helper';
import { getUserTypeLabel } from 'features/settings/users/config';
import { IRefetchActionType } from 'hooks/types';
import useAPI from 'hooks/useAPI';
import { AssetStatus, IAssetListDetails } from 'services/asset-service/asset.modal';
import { IGlobalSearchModule, IListApiResponse } from 'services/common/common.modal';
import { JobDetails } from 'services/job-service/job.modal';
import { IPartsList } from 'services/parts/parts.modal';
import {
  IOrganisationDetails,
  IUserDetailsResponse,
  ILocationDetails,
  ILocationGroupDetails,
} from 'services/settings-service/settings.modal';
import { remCalc } from 'theme/utils';
import { addUrlId } from 'utils/commonUtils';

import { IModuleConfig } from './config';
// eslint-disable-next-line import/no-cycle
import { RenderValue, Wrapper } from './helperComponent';
import { useStyle, Spinner } from './style';

export interface IContentProps extends IModuleConfig {
  search: string;
  resetSearch: () => void;
  closeSearch: () => void;
}

function Content({
  module,
  api,
  search,
  listRoute,
  detailsRoute,
  resetSearch,
  closeSearch,
}: IContentProps): JSX.Element | null {
  const { classes } = useStyle();
  const { t } = useTranslation(['translation', 'settings']);
  const navigate = useNavigate();
  const { response, error, refetchApi, loading } = useAPI<
    IListApiResponse<Record<string, unknown>[]>
  >(api, {
    isDisabled: true,
  });

  const moduleText = t(`side_nav.${module}`);
  const getTitle = (count: number | undefined): JSX.Element => {
    return (
      <Box className={classes.title}>
        {moduleText}
        {count && (
          <span>
            ({count} {t('global_search.results')})
          </span>
        )}
      </Box>
    );
  };

  const getMoreResult = (count: number | undefined): JSX.Element | null => {
    return (count ?? 0) > 5 ? (
      <Typography
        sx={{
          fontSize: remCalc(12),
          color: '#0A78C1',
          fontWeight: 500,
          px: '16px',
          pb: '12px',
          pt: '0x',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={(): void => {
          closeSearch();
          navigate(`${listRoute}?${APIQueryConstant.searchQuery}=${encodeURIComponent(search)}`);
        }}
      >
        {t('global_search.view_all_results')}
      </Typography>
    ) : null;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAPI = useCallback(
    debounce((searchQuery: string): void => {
      if (refetchApi) {
        const query = {
          [APIQueryConstant.searchQuery]: searchQuery,
          page: 1,
          size: 5,
          filter: '',
          sortField: '',
          sortOrder: '',
        };
        refetchApi(IRefetchActionType.create, query);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (search) {
      getAPI(search);
    }
  }, [getAPI, search]);

  if (error && !loading) {
    return (
      <Box>
        {getTitle(undefined)}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            p: '10px 16px',
            fontSize: remCalc(14),
            color: '#DF2D2D',
          }}
        >
          <SVGIcon height='20' width='20' stroke='#DF2D2D' icon={Alert} />
          {t('global_search.error', { module: moduleText })}
        </Box>
      </Box>
    );
  }

  if (!loading && !response?.data.length) {
    return (
      <>
        {getTitle(undefined)}
        <Typography
          sx={{ fontSize: remCalc(12), color: '#737373', fontWeight: 500, p: '10px 16px' }}
        >
          {t('global_search.no_result')}
        </Typography>
      </>
    );
  }

  const getContent = (): JSX.Element => {
    switch (module) {
      case IGlobalSearchModule.assets: {
        const { data = [], meta } =
          (response as unknown as IListApiResponse<IAssetListDetails[]>) || {};
        return (
          <>
            {getTitle(meta?.count)}
            {data?.map(({ assetId, status, externalId, type, homeLocation, organisation }) => {
              const details = [
                externalId,
                type?.name ?? '-',
                homeLocation?.name,
                organisation?.name,
              ];
              return (
                <Wrapper
                  key={assetId}
                  detailsRoute={addUrlId(detailsRoute, assetId)}
                  listRoute={listRoute}
                  resetSearch={resetSearch}
                  search={search}
                  closeSearch={closeSearch}
                >
                  <>
                    <Typography>
                      {status && <AssetStatusIcon status={status as unknown as AssetStatus} />}
                    </Typography>
                    {details.map((d, index) => {
                      return (
                        <RenderValue key={d} length={details?.length} index={index}>
                          {d}
                        </RenderValue>
                      );
                    })}
                  </>
                </Wrapper>
              );
            })}
            {getMoreResult(meta?.count)}
          </>
        );
      }

      case IGlobalSearchModule.jobs: {
        const { data = [], meta } = (response as unknown as IListApiResponse<JobDetails[]>) || {};
        return (
          <>
            {getTitle(meta?.count)}
            {data?.map(({ jobId, asset, status, user, jobType }) => {
              const { name: userName } = user ?? {};
              const details = [
                asset.externalId,
                getStatusLabel(status),
                getJobType(jobType),
                userName ?? '-',
              ];
              return (
                <Wrapper
                  key={jobId}
                  detailsRoute={addUrlId(detailsRoute, jobId)}
                  listRoute={listRoute}
                  resetSearch={resetSearch}
                  search={search}
                  closeSearch={closeSearch}
                >
                  {details.map((d, index) => {
                    return (
                      <RenderValue length={details?.length} index={index}>
                        {d}
                      </RenderValue>
                    );
                  })}
                </Wrapper>
              );
            })}
            {getMoreResult(meta?.count)}
          </>
        );
      }

      case IGlobalSearchModule.parts: {
        const { data = [], meta } = (response as unknown as IListApiResponse<IPartsList[]>) || {};

        return (
          <>
            {getTitle(meta?.count)}
            {data?.map(({ name, partId }) => {
              const details = [name];
              return (
                <Wrapper
                  key={partId}
                  listRoute={listRoute}
                  resetSearch={resetSearch}
                  search={search}
                  closeSearch={closeSearch}
                >
                  {details.map((d, index) => {
                    return (
                      <RenderValue length={details?.length} index={index}>
                        {d}
                      </RenderValue>
                    );
                  })}
                </Wrapper>
              );
            })}
            {getMoreResult(meta?.count)}
          </>
        );
      }

      case IGlobalSearchModule.organisation_locations: {
        const { data = [], meta } =
          (response as unknown as IListApiResponse<ILocationDetails[]>) || {};

        return (
          <>
            {getTitle(meta?.count)}
            {data?.map(({ name, locationId }) => {
              const details = [name];
              return (
                <Wrapper
                  key={locationId}
                  listRoute={listRoute}
                  resetSearch={resetSearch}
                  search={search}
                  closeSearch={closeSearch}
                  detailsRoute={addUrlId(detailsRoute, locationId)}
                >
                  {details.map((d, index) => {
                    return (
                      <RenderValue length={details?.length} index={index}>
                        {d}
                      </RenderValue>
                    );
                  })}
                </Wrapper>
              );
            })}
          </>
        );
      }

      case IGlobalSearchModule.location_groups: {
        const { data = [], meta } =
          (response as unknown as IListApiResponse<ILocationGroupDetails[]>) || {};

        return (
          <>
            {getTitle(meta?.count)}
            {data?.map(({ name, locationGroupId }) => {
              const details = [name];
              return (
                <Wrapper
                  key={locationGroupId}
                  listRoute={listRoute}
                  resetSearch={resetSearch}
                  search={search}
                  closeSearch={closeSearch}
                  detailsRoute={addUrlId(detailsRoute, locationGroupId)}
                >
                  {details.map((d, index) => {
                    return (
                      <RenderValue length={details?.length} index={index}>
                        {d}
                      </RenderValue>
                    );
                  })}
                </Wrapper>
              );
            })}
          </>
        );
      }

      case IGlobalSearchModule.organisations: {
        const { data = [], meta } =
          (response as unknown as IListApiResponse<IOrganisationDetails[]>) || {};

        return (
          <>
            {getTitle(meta?.count)}
            {data?.map(({ name, organisationId, totalAssets, totalUsers, address }) => {
              const details = [name, totalAssets, totalUsers, address.country];
              return (
                <Wrapper
                  key={organisationId}
                  detailsRoute={addUrlId(detailsRoute, organisationId)}
                  listRoute={listRoute}
                  resetSearch={resetSearch}
                  search={search}
                  closeSearch={closeSearch}
                >
                  {details.map((d, index) => {
                    return (
                      <RenderValue length={details?.length} index={index}>
                        {d}
                      </RenderValue>
                    );
                  })}
                </Wrapper>
              );
            })}
            {getMoreResult(meta?.count)}
          </>
        );
      }

      case IGlobalSearchModule.users: {
        const { data = [], meta } =
          (response as unknown as IListApiResponse<IUserDetailsResponse[]>) || {};

        return (
          <>
            {getTitle(meta?.count)}
            {data?.map(({ name, email, userId, phone, userType, organisation }) => {
              const details = [
                name,
                email,
                phone,
                userType ? t(getUserTypeLabel(userType), { ns: 'settings' }) : '-',

                organisation?.name,
              ];
              return (
                <Wrapper
                  key={userId}
                  detailsRoute={addUrlId(detailsRoute, userId)}
                  listRoute={listRoute}
                  resetSearch={resetSearch}
                  search={search}
                  closeSearch={closeSearch}
                >
                  {details.map((d, index) => {
                    return (
                      <RenderValue length={details?.length} index={index}>
                        {d}
                      </RenderValue>
                    );
                  })}
                </Wrapper>
              );
            })}
            {getMoreResult(meta?.count)}
          </>
        );
      }

      default:
        return (
          <>
            <Box className={classes.title}>{module}</Box>
            <Typography
              sx={{ fontSize: remCalc(12), color: '#737373', fontWeight: 500, p: '10px 16px' }}
            >
              {t('global_search.no_result')}
            </Typography>
          </>
        );
    }
  };

  return loading ? (
    <>
      <Box className={classes.title}>{module}</Box>
      <Box className={classes.loaderContent}>
        <Spinner>
          <SVGIcon height='20' width='20' stroke='#E6E6E6' icon={LoaderIcon} />
        </Spinner>
        <Typography sx={{ ml: '12px' }}>{t('global_search.loading_result')}</Typography>
      </Box>
    </>
  ) : (
    getContent()
  );
}

export default React.memo(Content);
