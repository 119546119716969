import { useState } from 'react';

import { Box } from '@mui/material';

import { remCalc } from 'theme/utils';

import CommonSwiper from '../common-swiper/CommonSwiper';

interface IImageListProps {
  images: string[];
}

function IImageList({ images }: IImageListProps): JSX.Element {
  const [modalStatus, updateModalStatus] = useState<boolean>(false);

  const copy = [...images];

  const displayImage = copy.splice(0, 2);

  const totalSlide = [...displayImage, copy.length];

  const toggleModal = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    updateModalStatus((prev) => !prev);
  };

  const getImageList = (): JSX.Element[] => {
    return images.map((url: string) => (
      <img
        src={url}
        alt='img'
        onError={({ currentTarget }): void => {
          currentTarget.onerror = null;
          currentTarget.src = '/images/placeholder.svg';
        }}
      />
    ));
  };

  return (
    <>
      {modalStatus && <CommonSwiper slides={getImageList()} onClose={toggleModal} />}
      <Box
        onClick={toggleModal}
        sx={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          minWidth: totalSlide.length * 20,
        }}
      >
        {totalSlide.map((url, index) => {
          if (!url) {
            return null;
          }
          return (
            <Box
              key={url}
              sx={{
                width: '40px',
                height: '40px',
                border: '0.528px solid #D6D6D6',
                borderRadius: '3.167px',
                background: '#FAFAFA',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: remCalc(12),
                color: '#141414',
                '& img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                },
                position: 'absolute',
                left: index * 20,
              }}
            >
              {index === totalSlide.length - 1 ? (
                `+${url}`
              ) : (
                <img
                  src={url as unknown as string}
                  alt='img'
                  onError={({ currentTarget }): void => {
                    currentTarget.onerror = null;
                    currentTarget.src = '/images/placeholder.svg';
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default IImageList;
