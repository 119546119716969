import React from 'react';

import { List, ListItemButton, ListItemText, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import SVGIcon from 'common/components/svg-icon/SVGIcon';
import useLaunchDark from 'hooks/useLaunchDark';
import usePermission from 'hooks/usePermission';

import { ISettingsSideNavLink } from './config';
import { useStyle } from './style';

function NavLinkSetting({ label, child = [], icon }: ISettingsSideNavLink): JSX.Element {
  const { t } = useTranslation();

  const { classes } = useStyle();

  const { getModulePermission } = useLaunchDark([]);
  const { isPermissionAvailable } = usePermission();

  const labelText = t(label);

  const filteredChild = child.filter((v) => getModulePermission(v.darkLaunchModule));

  const getSideNavLink = (): ISettingsSideNavLink[] => {
    return filteredChild.filter(({ module }) => {
      if (module) {
        return isPermissionAvailable(module);
      }
      return true;
    });
  };

  const permissionFilteredChild = getSideNavLink();

  if (!permissionFilteredChild.length) {
    return <Box />;
  }

  return (
    <Box sx={{ mb: '16px' }}>
      <ListItemButton className='side-nav-item'>
        {icon && <SVGIcon icon={icon} fontSize='small' stroke='#000' />}
        <ListItemText primary={labelText} className='nav-link-text' />
      </ListItemButton>
      <List
        sx={{ width: '100%', maxWidth: 360, pt: 0, pb: 0, bgcolor: 'background.paper' }}
        component='nav'
        className={classes.childList}
      >
        {permissionFilteredChild.map((sideNav) => {
          const { label: childLabel } = sideNav;

          if (sideNav?.child?.length) {
            return (
              <React.Fragment key={sideNav.path}>
                <ListItemButton className='side-nav-item dropdown-item'>
                  <NavLink
                    to={sideNav.path}
                    key={sideNav.path}
                    className={({ isActive }): string => (isActive ? 'nav-active' : '')}
                  >
                    <ListItemText primary={t(childLabel)} className='nav-link-text' />
                  </NavLink>
                </ListItemButton>
                <List component='div' disablePadding>
                  {sideNav?.child?.map(({ label: subChildLabel, path: childPath }): JSX.Element => {
                    return (
                      <NavLink
                        to={childPath}
                        key={subChildLabel}
                        className={({ isActive }): string => (isActive ? 'nav-active' : '')}
                      >
                        <ListItemButton sx={{ pl: 4 }} className='side-nav-item'>
                          <ListItemText
                            primary={t(subChildLabel)}
                            className='child-nav-link-text'
                          />
                        </ListItemButton>
                      </NavLink>
                    );
                  })}
                </List>
              </React.Fragment>
            );
          }

          return (
            <NavLink
              to={sideNav.path}
              key={sideNav.path}
              className={({ isActive }): string => (isActive ? 'nav-active' : '')}
            >
              <ListItemButton className='child-side-nav-item' key={childLabel}>
                <ListItemText primary={t(childLabel)} className='child-nav-link-text' />
              </ListItemButton>
            </NavLink>
          );
        })}
      </List>
    </Box>
  );
}

export default NavLinkSetting;
