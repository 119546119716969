import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps,
  SxProps,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import CustomLoader from 'common/components/component-loader/componentLoader';
import { remCalc } from 'theme/utils';
import { mobileAndTabletCheck } from 'utils/commonUtils';

import Transition from './Transition';

interface ICustomModalProps extends Omit<DialogProps, 'title'> {
  open: boolean;
  title: string | JSX.Element;
  children: React.ReactNode;
  footer?: JSX.Element;
  loader?: boolean;
  onClose?: () => void;
  customTitle?: () => JSX.Element;
  sx?: SxProps;
  titleSx?: SxProps;
  paperSx?: SxProps;
  hideRequiredLabel?: boolean;
}

function CustomModal({
  open,
  maxWidth = 'md',
  title,
  children,
  footer,
  loader = false,
  onClose,
  customTitle,
  sx = {},
  titleSx = {},
  paperSx = {},
  hideRequiredLabel = false,
}: ICustomModalProps): JSX.Element {
  const isMobile = mobileAndTabletCheck();

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      fullScreen={isMobile}
      open={open}
      scroll='paper'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sx={{
        '.MuiPaper-root': {
          borderRadius: isMobile ? 0 : '8px',
          ...paperSx,
        },
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle
        sx={{
          '&:first-letter': {
            textTransform: 'capitalize',
          },
          position: 'relative',
          ...titleSx,
        }}
      >
        {customTitle ? customTitle() : title}

        {onClose && (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 17,
              top: '50%',
              transform: 'translateY(-50%)',
              color: (theme) => theme.palette.grey[500],
              ...(isMobile ? { border: '1px solid #D6D6D6', borderRadius: '4px' } : {}),
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers sx={sx} id='modal-main-content'>
        {loader && <CustomLoader />}
        {!hideRequiredLabel && (
          <Typography
            sx={{
              fontSize: remCalc(12),
              fontWeight: 400,
              lineHeight: '16px',
              color: '#141414',
              mb: '16px',
            }}
          >
            Fields with * are required
          </Typography>
        )}
        {children}
      </DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
}
CustomModal.defaultProps = {
  footer: null,
  loader: false,
  onClose: null,
  customTitle: null,
  sx: {},
  titleSx: {},
  paperSx: {},
  hideRequiredLabel: false,
};

export default CustomModal;
