import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IContainerProps {
  isMobileRoute?: boolean;
}

type IRootContainerProps = IContainerProps & GridProps;

const RootContainer = styled(Grid)<IRootContainerProps>(() => ({
  width: '100vw',
  height: 'calc(100vh - 56px)',
  position: 'relative',
  flexDirection: 'row',
  display: 'flex',
  padding: 0,
}));

export default RootContainer;
