import { makeStyles } from 'tss-react/mui';

import { remCalc } from 'theme/utils';

export const useStyle = makeStyles()(() => ({
  root: {
    padding: '24px 16px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: remCalc(14),
    color: '#737373',
    fontWeight: 500,
    marginBottom: '20px',
  },
  backButton: {
    height: '24px',
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #D6D6D6',
    borderRadius: '4px',
    marginRight: '12px',
  },
  sideNavList: {
    height: '100%',
    '& .nav-link-text': {
      marginLeft: '12px',
      color: '#141414',
      fontWeight: 600,
      fontSize: remCalc(14),
      '& > span': {
        fontWeight: 600,
        fontSize: remCalc(14),
        lineHeight: '20px',
      },
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&.nav-active': {
        '& .side-nav-item': {
          background: '#FAFAFA',
        },
      },
    },
    '& .side-nav-item': {
      height: '24px',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      fontSize: remCalc(14),
      fontWeight: 600,
      marginBottom: '12px',
      '&:hover': {
        background: 'none',
      },
      '&.dropdown-item': {
        '& .nav-link-text': {
          marginLeft: '37px',
        },
      },
    },
  },
  childList: {
    '& .child-nav-link-text': {
      marginLeft: '36px',
      color: '#737373',
      '& > span': {
        fontWeight: 500,
        fontSize: remCalc(14),
        lineHeight: '20px',
      },
    },
    '& .child-side-nav-item': {
      height: '24px',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      fontSize: remCalc(14),
      fontWeight: 600,
    },
  },
}));
