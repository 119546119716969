export enum IFormInputType {
  TextInput = 'text',
  NumberInput = 'number',
  SingleSelect = 'select',
  MultiSelect = 'select-multiple',
  Attachment = 'attachment',
  Date = 'date',
  DateTime = 'datetime',
  Checkbox = 'checkbox',
  Radio = 'radio',
}

export interface IFieldDataBase {
  label: string;
  placeholder: string;
  hintText: string;
  toolTipText: string;
  defaultValue: string | number;
  required: boolean;
  id: string;
  type: IFormInputType;
}

export interface IFieldDataInput extends IFieldDataBase {
  maxChar?: number | null;
}

export interface IFieldDataSelect extends IFieldDataBase {
  options: string[];
  inputType: 'dropdown' | 'radio';
}

export interface IFieldDataDate extends IFieldDataBase {
  captureTime: boolean;
}

export type IFieldType = IFieldDataBase | IFieldDataInput | IFieldDataSelect | IFieldDataDate;
