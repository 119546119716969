/* eslint-disable import/no-unresolved */
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import './style.css';
import ModalPortal from './ReactPortal';

interface ICommonSwiperProps {
  slides: JSX.Element[];
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

function CommonSwiper({ slides, onClose }: ICommonSwiperProps): JSX.Element {
  return (
    <ModalPortal>
      <Swiper navigation modules={[Navigation]} className='mySwiper'>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            cursor: 'pointer',
            zIndex: 9,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {slides.map((el, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={index}>{el}</SwiperSlide>
        ))}
      </Swiper>
    </ModalPortal>
  );
}

export default CommonSwiper;
