import React from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

import ROUTES from 'constants/routes';
import usePermission from 'hooks/usePermission';

const withAuthHocFun = <P extends object>(
  Component: React.ComponentType<P>,
  isMobileRoute?: boolean,
) =>
  function WithAuthHoc(props: unknown): JSX.Element | null {
    const { isPermissionAvailableBasedOnURL } = usePermission();

    const { isPermitted, module } = isPermissionAvailableBasedOnURL();

    if (!isPermitted) {
      return <Navigate to={`${ROUTES.USER.NO_PERMISSION}?module=${module}`} />;
    }
    return <Component {...(props as P)} isMobileRoute={isMobileRoute} />;
  };

const withAuthHoc = (Component: React.ComponentType, isMobileRoute?: boolean): JSX.Element => {
  const HocComponent = withAuthenticationRequired(withAuthHocFun(Component, isMobileRoute));
  return <HocComponent />;
};

export default withAuthHoc;
