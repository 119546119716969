/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';

import { Box, Typography } from '@mui/material';
import QRScanner1 from 'qr-scanner';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Alert } from 'assets/images/common/alert.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';

import useStyles from './styles';

export interface IQrScannerProps {
  onChange: (id: string) => void;
  loading: boolean;
}

function QrScanner({ onChange, loading }: IQrScannerProps, ref: React.Ref<unknown>): JSX.Element {
  const { classes } = useStyles();
  const [cameraInstance, updateCameraInstance] = useState<QRScanner1 | null>(null);
  const [cameraAccessError, updateCameraAccessError] = useState<string>('');
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    startScanner(): void {
      cameraInstance?.start();
    },
    destroyScanner(): void {
      cameraInstance?.stop();
      cameraInstance?.destroy();
      updateCameraInstance(null);
    },
  }));

  const onResult = useCallback(
    (decodedText: string): void => {
      onChange(decodedText);
    },
    [onChange],
  );

  useEffect(() => {
    let instance: QRScanner1 | null = null;

    const videoElem = document.getElementById('camera') as HTMLVideoElement;
    if (videoElem && !cameraInstance) {
      instance = new QRScanner1(
        videoElem,
        (result): void => {
          instance?.pause().then(() => {
            // @ts-ignore
            onResult(result.data);
          });
        },
        {
          // @ts-ignore
          highlightScanRegion: true,
          // @ts-ignore
          highlightCodeOutline: true,
          calculateScanRegion: (video: HTMLVideoElement): QRScanner1.ScanRegion => {
            const smallestDimension = Math.min(video.videoWidth, video.videoHeight);
            const scanRegionSize = Math.round((1 / 3) * smallestDimension);
            return {
              x: Math.round((video.videoWidth - scanRegionSize) / 2),

              y: Math.round((video.videoHeight - scanRegionSize) / 2),
              width: scanRegionSize,
              height: scanRegionSize,
              downScaledWidth: QRScanner1.DEFAULT_CANVAS_SIZE,
              downScaledHeight: QRScanner1.DEFAULT_CANVAS_SIZE,
            };
          },
        },
      );

      instance.start();
      instance.setInversionMode('both');
      updateCameraInstance(instance);
    }
  }, [updateCameraInstance, onResult, cameraInstance]);

  useEffect(() => {
    return () => {
      // alert();
      // cameraInstance?.stop();
      // cameraInstance?.destroy();
    };
  }, [cameraInstance]);

  return (
    <Box className={classes.root}>
      <div id='camera-wrapper' className={classes.camContentWrapper}>
        <video id='camera' style={{ height: '100%', width: '100%' }} />
        {cameraAccessError && (
          <>
            <Typography>{t('common.cam_access_denied')}</Typography>
            <SVGIcon style={{ width: '50px', height: '50px' }} icon={Alert} fontSize='large' />
          </>
        )}
      </div>
    </Box>
  );
}

export default React.memo(forwardRef(QrScanner));
