import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Outlet } from 'react-router-dom';

import AppDrawer from 'common/components/app-drawer/AppDrawer';
import MainContentWrapper from 'common/components/styled/MainContentWrapper';
import RootContainer from 'common/components/styled/RootContainer';
import Navbar from 'common/layout/navbar/Navbar';
import { useAppSelector } from 'store/hooks';
import { selectIsDrawerOpen } from 'store/reducers/appDrawerSlice';

interface ICommonLayoutProps {
  isMobileRoute?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AuthLayout({ isMobileRoute }: ICommonLayoutProps): JSX.Element {
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <Navbar />
      <RootContainer sx={{ backgroundColor: '#FCFCFC', ...(mobileMatches && { height: '100%' }) }}>
        <AppDrawer mobileMatches={mobileMatches} />
        <MainContentWrapper
          sx={{ position: 'relative' }}
          open={mobileMatches ? true : isDrawerOpen}
        >
          <Outlet />
        </MainContentWrapper>
      </RootContainer>
    </Box>
  );
}
export function AuthLayoutWithoutSideNav({ isMobileRoute }: ICommonLayoutProps): JSX.Element {
  return (
    <RootContainer isMobileRoute={isMobileRoute}>
      <Outlet />
    </RootContainer>
  );
}

AuthLayout.defaultProps = {
  isMobileRoute: false,
};

AuthLayoutWithoutSideNav.defaultProps = {
  isMobileRoute: false,
};

export default AuthLayout;
