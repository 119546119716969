/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { User } from '@auth0/auth0-react';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { segmentWriteKey } from 'constants/env';

import { IPageName } from './config';

class SegmentAnalytics {
  private analytics: AnalyticsBrowser | null = null;

  public init(user: User): void {
    const analyticsInstance = AnalyticsBrowser.load({
      writeKey: segmentWriteKey as string,
    });

    this.analytics = analyticsInstance;

    const { sub, given_name, family_name, email } = user;

    analyticsInstance.identify(sub, {
      name: `${given_name} ${family_name}`,
      email,
    });
  }

  public addPage(page: IPageName): void {
    this.analytics?.page(page);
  }
}

export default new SegmentAnalytics();
