import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import usePageLoaderStyles from './pageLoaderStyles';

function PageLoader(): JSX.Element {
  const { classes } = usePageLoaderStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress color='primary' size='2rem' />
    </Box>
  );
}

export default PageLoader;
