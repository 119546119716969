/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface ScannerState {
  isScannerOpen: boolean;
}

const initialState: ScannerState = {
  isScannerOpen: false,
};

export const scannerSlice = createSlice({
  name: 'scannerSlice',
  initialState,
  reducers: {
    setIsScannerOpen: (state, action: PayloadAction<boolean>) => {
      state.isScannerOpen = action.payload;
    },
  },
});

export const { setIsScannerOpen } = scannerSlice.actions;

export const selectIsScannerOpen = (state: RootState): boolean => state.scannerSlice.isScannerOpen;

export default scannerSlice.reducer;
