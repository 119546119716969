import { PaletteOptions } from '@mui/material';

const PaletteOverRide: PaletteOptions = {
  primary: {
    main: '#1E1E20',
  },
  secondary: {
    main: '#0A78C1',
    dark: '#FBFF47',
  },
  error: {
    main: '#e53935',
  },
  info: {
    main: '#00b0ff',
  },
  common: {
    white: '#FFFFFF',
    black: '#141414',
  },
  grey: {
    500: '#737373',
    300: '#FCFCFC',
  },
};
export default PaletteOverRide;
