import React from 'react';

import { Box, SxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import useStyle from './style';

interface CustomLoaderProps {
  sx?: SxProps;
}

function CustomLoader({ sx }: CustomLoaderProps): JSX.Element {
  const { classes } = useStyle();
  return (
    <Box className={classes.root} sx={sx} data-testid='component-loader'>
      <CircularProgress />
    </Box>
  );
}

CustomLoader.defaultProps = {
  sx: {},
};
export default CustomLoader;
