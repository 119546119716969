/* eslint-disable import/no-cycle */
import { AxiosResponse } from 'axios';

import { APIS } from 'constants/api.constants';
import { deepFind } from 'hooks/useAPI';

import { BaseApi } from './base-api';
import { IListApiResponse } from './common.modal';
import CustomError from './error';
import toastInstance from './toastInstance';

interface IApiResponse {
  message?: string;
  data?: IApiResponse;
}
interface IErrorReturnTyp {
  errorMessage: string;
  statusCode: number;
}

export interface IApiError {
  status: string;
  title: string;
  detail: string;
}

export const getErrorMessage = (
  apiResponse: AxiosResponse<IApiResponse, unknown>,
  defaultMessage = 'Some error occurred, please try again',
): IErrorReturnTyp => {
  let errorMessage = defaultMessage;
  if (apiResponse) {
    if (apiResponse.data?.message) {
      errorMessage = apiResponse.data.message;
    } else if (apiResponse.data?.data && typeof apiResponse.data.data === 'string') {
      errorMessage = apiResponse.data.data;
    } else if (apiResponse.data?.data?.message) {
      errorMessage = apiResponse.data.data.message;
    }
  }
  return { errorMessage, statusCode: apiResponse?.status };
};

export const processResponse = <T>(
  response: AxiosResponse<T, unknown>,
  errorMessage = 'Error while processing request',
  validStatus?: number,
): T | Error => {
  const statusCheckResp =
    validStatus && response
      ? response.status === validStatus
      : response.status >= 200 && response.status < 300;

  if (response.status === 204) {
    return response.data;
  }

  if (response?.data && statusCheckResp) {
    return response.data;
  }
  const { errorMessage: errorMessageString, statusCode } = getErrorMessage(response, errorMessage);
  throw new CustomError(errorMessageString, statusCode);
};

export const constructErrorMessage = (errors: IApiError[]): string => {
  let errorMessage = '';
  if (errors.length) {
    errorMessage = errors.reduce((acc, { detail }) => {
      return `${acc}${acc ? '\n' : ''}${detail}`;
    }, '');
  }
  return errorMessage;
};

export const handleErrorMessage = (errors: IApiError[] | undefined): void => {
  // TODO need to implement translations
  let errorMessage = 'Something went wrong. Please try again';
  if (errors?.length) {
    errorMessage = constructErrorMessage(errors);
  }
  toastInstance.toast(errorMessage);
};

export const deleteModule = async (url: string): Promise<Error | null> => {
  const response = await BaseApi.delete(url);
  return processResponse<null>(response);
};

export const handleAutocomplete = async <T>(
  apiPath: string,
  searchQuery: string,
  query: Record<string, unknown> | undefined,
): Promise<Error | IListApiResponse<T>> => {
  const response = await BaseApi.get(deepFind(APIS, apiPath), {
    params: { ...(searchQuery ? { searchQuery } : ''), page: 1, ...(query ?? {}) },
  });
  return processResponse<IListApiResponse<T>>(response);
};
