/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import LoginIssueInfo from 'common/layout/LoginIssueInfo';
import PageLoader from 'common/pages/PageLoader/PageLoader';
// eslint-disable-next-line import/order
import { baseApiInstance } from 'services/common/base-api';
import { SnackbarUtilsConfig } from 'services/common/toastInstance';
import '../i18n';
import { store } from 'store/store';
import SegmentAnalytics from 'utils/segment/segment';

import AppRoute from '../routes/AppRoute';
import theme from '../theme/default/default';
import './index.css';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

function App(): JSX.Element {
  const { isLoading, error, getAccessTokenSilently, user } = useAuth0();

  const [isTokenHandlerUpdate, updateTokenHandlerStatus] = useState(false);

  useEffect(() => {
    if (!isLoading && !error) {
      // adding get token function to baseAPIinstance. `getAccessTokenSilently` will execute in every request in baseApiInstance interceptors and pass auth token in the headers
      baseApiInstance.addGetTokenHandler(getAccessTokenSilently);
      updateTokenHandlerStatus(true);
    }
  }, [isLoading, error, getAccessTokenSilently, updateTokenHandlerStatus]);

  useEffect(() => {
    if (!isLoading && !error && user) {
      SegmentAnalytics.init(user);
    }
  }, [isLoading, error, user]);

  if (isLoading || !isTokenHandlerUpdate) {
    return <PageLoader />;
  }
  if (error) {
    return <LoginIssueInfo message={error.message} />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              maxSnack={1}
              autoHideDuration={5000}
            >
              <SnackbarUtilsConfig />
              <AppRoute />
            </SnackbarProvider>
          </ThemeProvider>
        </CacheProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
