/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { useEffect, useState, useCallback } from 'react';

import { IFilterData } from 'common/components/filter-panel/FilterPanel';
import { APIS } from 'constants/api.constants';
import { BaseApi } from 'services/common/base-api';
import { processResponse } from 'services/common/common';
import { IListApiResponse, ICustomMeta } from 'services/common/common.modal';
import { IOptions } from 'services/settings-service/settings.modal';
import { addUrlId, CommonObj, removeEmpty } from 'utils/commonUtils';

import { IApiInterface, ExtraOption, IUseAPIReturnType, IRefetchActionType } from './types';
import useQueryParams from './useQueryParams';

export function deepFind(obj: IApiInterface, path: string): string {
  const [module, pathname] = path.split('.');
  return obj[module][pathname];
}

function useAPI<T extends object>(apiPath: string, options?: ExtraOption): IUseAPIReturnType<T> {
  const {
    urlId = null,
    isDisabled = false,
    syncWithQuery = false,
    defaultQuery = {},
    replaceId = false,
  } = options ?? {};
  const [error, updateError] = useState<unknown | null>(null);
  const [response, updateResponse] = useState<T | null>(null);
  const [meta, updateMeta] = useState<ICustomMeta | null>(null);
  const [loading, updateLoader] = useState<boolean>(true);
  const { modifiedQuery: query, updateQuery } = useQueryParams();

  const convertFilterToAPIFormat = (data: IFilterData[]): Record<string, unknown> => {
    return data.reduce((acc, d) => {
      const { id, secondaryId, value, pattern, type } = d;
      const field = secondaryId ?? id;

      if (type === 'none') {
        acc = {
          ...acc,
          ...(pattern as unknown as object),
        };
        return acc;
      }

      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        acc[field as keyof typeof acc] = (value as IOptions).value;
      } else if (Array.isArray(value)) {
        acc[field as keyof typeof acc] = (value as IOptions[]).map(
          ({ value: dataValue }) => dataValue,
        );
      } else {
        acc[field as keyof typeof acc] = pattern ? pattern.replace('data', value as string) : value;
      }

      return acc;
    }, {} as Record<string, unknown>);
  };

  const formatQuery = useCallback((queries: Record<string, unknown>): Record<string, unknown> => {
    if (queries.filter) {
      queries = {
        ...queries,
        ...convertFilterToAPIFormat(queries.filter as unknown as IFilterData[]),
      };

      delete queries.filter;
    }
    return queries;
  }, []);

  const handleMeta = useCallback(
    (responseDetails: IListApiResponse<T>): void => {
      if (Object.prototype.hasOwnProperty.call(responseDetails, 'meta')) {
        const data = responseDetails.data as object[];
        updateMeta({ ...responseDetails.meta, totalRow: data.length });
      }
    },
    [updateMeta],
  );

  const handleAPI = useCallback(
    async (params?: Record<string, unknown>): Promise<void> => {
      try {
        updateLoader(true);
        const url = replaceId
          ? addUrlId(deepFind(APIS, apiPath), urlId as string)
          : `${deepFind(APIS, apiPath)}${urlId ? `/${urlId}` : ''}`;
        const apiResponse = await BaseApi.get(url, {
          params: removeEmpty(formatQuery(params ?? {})) as CommonObj,
        });
        const data = processResponse(apiResponse);
        updateResponse(data);
        handleMeta(data);
        updateLoader(false);
        updateError(null);
      } catch (e: unknown) {
        updateLoader(false);
        updateError(e);
        updateResponse(null);
      }
    },
    [updateError, updateResponse, handleMeta, apiPath, urlId, formatQuery, replaceId],
  );

  const refetchApi = (
    action: IRefetchActionType,
    customQuery?: Record<string, unknown>,
    // eslint-disable-next-line consistent-return
  ): void => {
    const currentPageNumber = (response as unknown as { meta: ICustomMeta })?.meta?.page;
    const currentPage = currentPageNumber ? Number(currentPageNumber) : Number(query.page);

    // CORNER-CASE: if only one entry left in list and if user try to delete that entry. the page should land on previous page(current page -1)
    if (IRefetchActionType.delete === action) {
      const listLength = (response as unknown as { data: object[] }).data.length;

      if (listLength === 1 && currentPage > 1) {
        if (!syncWithQuery) {
          handleAPI({
            ...query,
            ...(defaultQuery ?? {}),
            ...(customQuery ?? {}),
            page: String(currentPage - 1),
          });
        } else {
          updateQuery({ ...query, page: String(currentPage - 1) });
        }
      }
    }

    if (!syncWithQuery) {
      handleAPI({
        // ...query,
        ...(defaultQuery ?? {}),
        page: currentPage ?? 1,
        ...(customQuery ?? {}),
      });
    } else {
      handleAPI({
        ...query,
        ...(defaultQuery ?? {}),
        ...(customQuery ?? {}),
      });
    }
  };

  useEffect(() => {
    if (syncWithQuery) {
      handleAPI(query);
    }
  }, [syncWithQuery, handleAPI, query]);

  useEffect(() => {
    if (!isDisabled && !syncWithQuery) {
      handleAPI();
    }
  }, [handleAPI, isDisabled, syncWithQuery]);

  return { error, response, loading, meta, refetchApi };
}

export default useAPI;
