import { NestedValue } from 'react-hook-form';

import { IAdditionFieldsPayload } from 'services/common/common.modal';
// eslint-disable-next-line import/no-cycle
import { JobStatus, JobType } from 'services/job-service/job.modal';
import { IOptions } from 'services/settings-service/settings.modal';

export interface IAssetDetails {
  externalId: string;
  organisationId: NestedValue<IOptions> | null;
  homeLocationId: NestedValue<IOptions> | null;
  type: {
    name: string;
    id: string;
  } | null;
  typeId: NestedValue<IOptions> | null;
  status: string | null;
  operatorId: NestedValue<IOptions> | null | '';
  model?: {
    name: string;
    id: string;
  };
  make: {
    name: string;
    id: string;
  } | null;
  makeId: NestedValue<IOptions> | null;
  modelId: NestedValue<IOptions> | null;
  iot: {
    type: string | null;
    serial: string | null;
  };
  assetId: string;
}

export interface IActiveJob {
  id: string;
  jobNumber: string;
  status: JobStatus;
  type: JobType;
}

export interface IAssetListDetails extends IAssetDetails, Partial<IAdditionFieldsPayload> {
  organisation: {
    name: string;
    id: string;
  };
  homeLocation: {
    name: string;
    id: string;
  };
  currentLocation: {
    name: string;
    id: string;
  };
  operator: {
    name: string;
    id: string;
    email: string;
  };
  activeJobs: IActiveJob[];
}

export interface IAssetChecks {
  frameDamaged: boolean;
  trailerNotDamaged: boolean;
  wheelsAreStraight: boolean;
  tyresPumped: boolean;
  lightsWorking: boolean;
  brakesWorking: boolean;
  batteryNotDamaged: boolean;
}

export interface IAssetAssignmentHistory {
  assignmentId: string;
  assetId: string;
  userId: string;
  organisationId: string;
  assetType: string;
  assignmentType: string;
  active: boolean;
  photos: string[];
  checks: IAssetChecks;
  manualAssignment: boolean;
  created: string;
  user: {
    id: string;
    name: string;
  };
  asset: {
    externalId: string;
    id: string;
  };
}

export interface IAssetFeedDetails {
  eventType: IAssetActivityFeedType;
  fromData: IAssetFeedDetailsChangeData;
  toData: IAssetFeedDetailsChangeData;
  userId: string;
  created: string;
  user: {
    id: string;
    name: string;
  };
}

export interface IAssetFeedDetailsChangeData {
  type?: {
    id?: string;
    name: string;
  };
  externalId?: string;
  organisation?: {
    id: string;
    name: string;
  };
  homeLocation?: {
    id: string;
    name: string;
  };
  currentLocation?: {
    id: string;
    name: string;
  };
  status?: AssetStatus;
  operator?: {
    id: string;
    name: string;
  };
  model?: {
    id: string;
    name: string;
  };
  additionalFields?: Record<string, string | null>;
  iot?: {
    type?: string;
    serial?: string;
  };
}

export enum IAssetActivityFeedType {
  AssetArchive = 'AssetArchive',
  AssetCreate = 'AssetCreate',
  AssetModify = 'AssetModify',
  ManufacturerArchive = 'ManufacturerArchive',
  ManufacturerCreate = 'ManufacturerCreate',
  ManufacturerModify = 'ManufacturerModify',
  ModelArchive = 'ModelArchive',
  ModelCreate = 'ModelCreate',
  ModelModify = 'ModelModify',
  AssetAssignmentCreate = 'AssetAssignmentCreate',
}

export interface IAssetActivity {
  eventType: string;
  fromData: IAssetDetails;
  toData: IAssetDetails;
  userId: string;
  created: string;
}

export type IAddAssetPayload = Omit<IAssetDetails, 'assetId'> & Partial<IAdditionFieldsPayload>;

export enum AssetStatus {
  active = 'Active',
  inActive = 'Inactive',
  decommissioned = 'Decommissioned',
}

export enum IotType {
  particle = 'particle',
  teltonika = 'teltonika',
  oyster = 'oyster',
}
