import { makeStyles } from 'tss-react/mui';

import { remCalc } from 'theme/utils';

export const useAppDrawerStyles = makeStyles()(() => ({
  root: {},
}));

export const useAppDrawerContentStyles = makeStyles()((theme) => ({
  root: {
    padding: '16px',
    position: 'relative',
    height: 'calc(100% - 56px)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  sideNavList: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .nav-link-text': {
      marginLeft: '12px',
      color: theme.palette.grey[500],
      fontSize: remCalc(14),
      '& > span': {
        fontWeight: '500',
        fontSize: remCalc(14),
      },
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&:last-child': {
        '& .side-nav-item': {
          marginBottom: '0',
        },
      },

      '&.nav-active .side-nav-item': {
        background: theme.palette.secondary.dark,
        '&::before': {
          content: '""',
          height: '78%',
          width: '3px',
          background: 'black',
          position: 'absolute',
          zIndex: 8,
          left: '0',
          borderRadius: '0px 7px 7px 0px',
          overflow: 'hidden',
        },
        '& svg': {
          stroke: theme.palette.common.black,
        },
        '& .nav-link-text': {
          color: theme.palette.common.black,
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    '& .side-nav-item': {
      height: '36px',
      '& svg': {
        stroke: theme.palette.grey[500],
      },
      borderRadius: '4px',
      marginBottom: '8px',
      padding: '6px',
      paddingLeft: '18px',
    },
  },
}));
