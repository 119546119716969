import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    position: 'absolute',
    display: 'flex',
    background: '#fff',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    left: 0,
    right: 0,
    justifyContent: 'center',
    zIndex: 4,
    opacity: '0.9',
  },
}));

export default useStyles;
