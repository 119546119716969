import React from 'react';

import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';

const formatQuery = (key: string, value: string): string | number => {
  switch (key) {
    case 'page':
      return parseInt(value, 10);
    default:
      return value;
  }
};

function paramsToObject(entries: IterableIterator<[string, string]>): Record<string, string> {
  const ob = Object.fromEntries(entries);
  return Object.keys(ob).reduce((acc, key): Record<string, string> => {
    return {
      ...acc,
      [key]: formatQuery(key, ob[key]),
    };
  }, {});
}

export function getFilters(filter: string): string {
  const data = atob(filter);
  return JSON.parse(data);
}

const defaultQueries = {
  page: 1,
  filter: '',
};

function useQueryParams(): {
  query: Record<string, unknown>;
  updateQuery: (updatedQuery: Record<string, string>) => void;
  modifiedQuery: Record<string, unknown>;
} {
  const { search } = useLocation();
  const navigate = useNavigate();
  const modifiedQuery = React.useMemo(() => {
    const urlParams = new URLSearchParams(search);
    const entries = urlParams.entries();

    const payload = {
      ...defaultQueries,
      ...paramsToObject(entries),
    };
    if (payload.filter) {
      payload.filter = getFilters(payload.filter);
    }
    return payload;
  }, [search]);

  const query = React.useMemo(() => {
    const urlParams = new URLSearchParams(search);
    const entries = urlParams.entries();

    const payload = {
      ...defaultQueries,
      ...paramsToObject(entries),
    };

    return payload;
  }, [search]);

  const updateQuery = (updatedQuery: Record<string, string>): void => {
    const urlParams = new URLSearchParams(search);
    const entries = urlParams.entries();
    const paramsPayload = {
      ...paramsToObject(entries),
      ...updatedQuery,
    };

    const params = createSearchParams({
      ...Object.fromEntries(Object.entries(paramsPayload).filter(([_, v]) => v !== '')),
    });

    params.forEach((value, key) => {
      if (!value) params.delete(key);
    });

    navigate({
      pathname: '',
      search: params.toString(),
    });
  };

  return { query, updateQuery, modifiedQuery };
}

export default useQueryParams;
