import * as yup from 'yup';

import { required } from 'utils/validationUtils';

const validation = yup
  .object({
    assetId: required.nullable(),
  })
  .required();

export default validation;
