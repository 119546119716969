import React, { SVGProps, ComponentType, useState, useRef, useLayoutEffect } from 'react';

import { SvgIconProps } from '@mui/material';

interface ISVGIconProps extends SvgIconProps {
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  trim?: boolean;
  style?: React.CSSProperties;
  adjustBox?: boolean;
}

function SVGIcon({ icon: Icon, trim, adjustBox, style, ...svgProps }: ISVGIconProps): JSX.Element {
  const svgRef = useRef<SVGSVGElement>(null);
  const [trimProps, setTrimProps] = useState<{ viewBox?: string }>({});

  useLayoutEffect(() => {
    if (trim && svgRef.current) {
      const bbox = svgRef.current.getBBox();
      const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(' ');
      setTrimProps({ viewBox });
    } else {
      setTrimProps({});
    }
  }, [Icon, trim]);

  useLayoutEffect(() => {
    if (adjustBox) {
      if (svgProps.height) {
        const viewBox = [
          0,
          0,
          String(svgProps.height).replace('px', ''),
          String(svgProps.width).replace('px', ''),
        ].join(' ');
        setTrimProps({ viewBox });
      } else {
        setTrimProps({});
      }
    }
  }, [Icon, svgProps.height, svgProps.width, adjustBox]);

  return <Icon {...svgProps} {...trimProps} ref={svgRef} style={{ cursor: 'pointer', ...style }} />;
}

SVGIcon.defaultProps = {
  trim: false,
  style: {},
  adjustBox: false,
};

export default SVGIcon;
