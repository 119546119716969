import React from 'react';

import { styled, Tooltip, tooltipClasses, TooltipProps, Box } from '@mui/material';

import { ReactComponent as InfoIcon } from 'assets/images/tabler-icon/info.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { remCalc } from 'theme/utils';

interface ITooltipProps {
  message: string;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: '12px',
    fontSize: remCalc(14),
  },
}));

function CustomTooltip({ message }: ITooltipProps): JSX.Element | null {
  if (!message) {
    return null;
  }

  return (
    <BootstrapTooltip title={message} placement='right'>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SVGIcon icon={InfoIcon} />
      </Box>
    </BootstrapTooltip>
  );
}

export default CustomTooltip;
