import React from 'react';

import { Typography, Box, capitalize } from '@mui/material';

import { getJobTypeConfigIcon } from 'components/settings/sidenav-settings/job-type-config/assets';
import { JobType, JobDetails } from 'services/job-service/job.modal';
import { remCalc } from 'theme/utils';

export const getJobType = (
  type: JobType | null,
  transfer?: JobDetails['transfer'],
): JSX.Element => {
  const commonCss = {
    border: '1px solid #E5E5E5',
    borderRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '4px 12px',
    gap: '4px',
    height: '32px',
    background: '#FAFAFA',
    '& img': {
      width: '24px !important',
      height: '24px !important',
    },
  };

  if (!type) {
    return <Box />;
  }

  const { iconType, name } = type;

  const { isPermanent } = transfer ?? { isPermanent: false };

  return (
    <Box sx={commonCss}>
      {getJobTypeConfigIcon(iconType)}
      <Typography
        sx={{ fontSize: remCalc(12), color: '#141414', fontWeight: 600, lineHeight: '16px' }}
      >
        {`${capitalize(name)} ${transfer ? `(${isPermanent ? 'Permanent' : 'Temporary'})` : ''}`}
      </Typography>
    </Box>
  );
};
