/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import axios, { AxiosError, AxiosInstance } from 'axios';

import { API_BASE_URL } from 'constants/api.constants';

import { handleErrorMessage, IApiError } from './common';

let isFistTimeLoading = false;

class BaseApiClass {
  private instance: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-type': 'application/json',
    },
  });

  private getToken?: (option: GetTokenSilentlyOptions) => Promise<string>;

  constructor() {
    this.initInterceptors();
  }

  addGetTokenHandler(func: () => Promise<string>): void {
    this.getToken = func;
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }

  private initInterceptors(): void {
    this.instance.interceptors.request.use(
      async (config) => {
        if (config.headers && this.getToken) {
          const orgId = localStorage.getItem('orgId');
          const token = await this.getToken({
            ignoreCache: !isFistTimeLoading,
            ...(orgId ? { organisationId: orgId } : {}),
          });

          if (!isFistTimeLoading) {
            isFistTimeLoading = true;
          }

          config.headers.authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        const { status } = error.response;
        if (status === 401) {
          window.location.reload();
        }
      },
    );

    this.instance.interceptors.response.use(
      undefined,
      (error: AxiosError<{ errors: IApiError[] }>) => {
        const { response } = error;
        if (response?.status && response?.status === 401) {
          window.location.reload();
        }

        if (response?.data?.errors || response?.status === 0) {
          handleErrorMessage(response?.data?.errors);
        }

        return Promise.reject(error);
      },
    );
  }
}

export const baseApiInstance = new BaseApiClass();

const axiosInstance = baseApiInstance.getInstance();

export const BaseApi = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete,
  patch: axiosInstance.patch,
};
