import { styled } from '@mui/material/styles';

export const DRAWER_WIDTH = 230;

interface MainContentWrapperProps {
  open?: boolean;
}

const MainContentWrapper = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<MainContentWrapperProps>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    overflowY: 'auto',
  }),
}));

export default MainContentWrapper;
