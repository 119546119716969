import React from 'react';

import { useSnackbar, SnackbarMessage, SnackbarKey, WithSnackbarProps } from 'notistack';

import CustomSnackbar, { SnackBarType } from 'common/components/snackbar/custom-snackbar';

let useSnackbarRef: WithSnackbarProps;
// eslint-disable-next-line react/function-component-definition
export const SnackbarUtilsConfig: React.FC = (): null => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  toast(message: string): void {
    useSnackbarRef.enqueueSnackbar(message, {
      // eslint-disable-next-line react/no-unstable-nested-components
      content: (key: SnackbarKey, mesg: SnackbarMessage): JSX.Element => {
        return <CustomSnackbar id={key} message={mesg} type={SnackBarType.error} />;
      },
    });
  },
};
