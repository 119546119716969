import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';

import '@fontsource/apfel-grotezk';

import React, { Suspense } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from 'react-dom/client';

import ErrorBoundary from 'common/layout/ErrorBoundary';
import PageLoader from 'common/pages/PageLoader/PageLoader';
import { auth0Domain, auth0ClientId, backendBaseUrl, muiLicenseKey } from 'constants/env';

import './i18n';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

LicenseInfo.setLicenseKey(muiLicenseKey as string);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <Suspense fallback={<PageLoader />}>
    <ErrorBoundary>
      <Auth0Provider
        domain={auth0Domain as string}
        clientId={auth0ClientId as string}
        redirectUri={window.location.origin}
        audience={backendBaseUrl}
        cacheLocation='localstorage'
        useRefreshTokens
      >
        <App />
      </Auth0Provider>
    </ErrorBoundary>
  </Suspense>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
