import { useAuth0 } from '@auth0/auth0-react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

function NoPermissionIssueInfo(): JSX.Element | null {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const handleLogout = (): void => {
    localStorage.removeItem('orgId');
    logout({ returnTo: window.location.origin });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '20px',
        height: '95vh',
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 100 }} />
      <Typography>{t('common.no_permission_error_msg')}</Typography>
      <Button
        variant='contained'
        sx={{
          background: '#000',
          '&:hover': {
            background: '#000',
          },
        }}
        onClick={handleLogout}
      >
        {t('common.click_here_to_logout')}
      </Button>
    </Box>
  );
}

export default NoPermissionIssueInfo;
