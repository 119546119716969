import * as React from 'react';

import { useSnackbar, SnackbarKey } from 'notistack';

import { ReactComponent as Close } from 'assets/images/common/close.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';

interface SnackbarActionProps {
  id: SnackbarKey;
}

function SnackbarAction({ id }: SnackbarActionProps): JSX.Element {
  const { closeSnackbar } = useSnackbar();

  return (
    <SVGIcon
      width='12px'
      height='12px'
      icon={Close}
      className='toast-close-icon'
      onClick={(): void => closeSnackbar(id)}
      fontSize='medium'
      style={{ position: 'absolute' }}
    />
  );
}

export default SnackbarAction;
