import React, { useRef, useMemo, useState, useEffect } from 'react';

import { Box, TextField, Typography, Popper, Grow, Paper, ClickAwayListener } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/images/tabler-icon/close.svg';
import { ReactComponent as SearchIcon } from 'assets/images/tabler-icon/search.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { APIQueryConstant } from 'constants/api.constants';
import useLaunchDark from 'hooks/useLaunchDark';
import { ILaunchDarkModules } from 'services/common/common.modal';
import { getURLParamByName } from 'utils/commonUtils';

import { IModuleConfig, modulesConfig } from './config';
import Content from './Content';
import MobileContent from './MobileContent';
import { useStyle } from './style';

function SearchBar(): JSX.Element | null {
  const { classes } = useStyle();

  const { permissions } = useLaunchDark([ILaunchDarkModules.GlobalSearch]);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [search, updateSearch] = useState('');
  const [open, setOpen] = React.useState(false);
  const [isGlobalSearch, updateGlobalSearch] = useState(false);
  const id = 'simple-popover34';
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation(['translation', 'settings']);

  const mobileMatches = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const getCurrentModuleDetails = (): IModuleConfig | undefined => {
    return modulesConfig.find(({ listRoute }) => listRoute === pathname);
  };

  useEffect(() => {
    const q = getURLParamByName(APIQueryConstant.searchQuery);

    updateSearch(q ?? '');
  }, [updateSearch, pathname]);

  const resetSearch = (): void => {
    setOpen(false);
    updateGlobalSearch(false);
    updateSearch('');
  };

  const closeSearch = (): void => {
    setOpen(false);
    updateGlobalSearch(false);
  };

  const handleClose = (event: Event | React.SyntheticEvent): void => {
    if (rootRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
    updateGlobalSearch(false);
  };

  const refCurrent = rootRef.current;

  const getPopoverWidth = useMemo((): string | number | undefined => {
    if (mobileMatches) {
      return !refCurrent?.clientWidth || refCurrent?.clientWidth < 400
        ? 400
        : refCurrent?.clientWidth;
    }

    return '500px';
  }, [mobileMatches, refCurrent]);

  if (!permissions[ILaunchDarkModules.GlobalSearch]) {
    return null;
  }

  const getComponent = (): JSX.Element => {
    if (mobileMatches) {
      return <MobileContent search={search} closeSearch={closeSearch} />;
    }

    if (!isGlobalSearch) {
      const currentModuleDetails = getCurrentModuleDetails();

      return (
        <>
          {currentModuleDetails && (
            <Typography
              className={classes.topContent}
              onClick={(): void => {
                setOpen(false);
                updateGlobalSearch(false);
                navigate(
                  `${currentModuleDetails.listRoute}?${APIQueryConstant.searchQuery}=${search}`,
                );
              }}
            >
              {t('global_search.search_result')} <strong>{search}</strong> in{' '}
              <strong>{t(currentModuleDetails.module)}</strong>
            </Typography>
          )}
          <Typography
            className={classes.topContent}
            onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
              e.preventDefault();
              updateGlobalSearch(true);
            }}
          >
            {t('global_search.search_result')} <strong>{search}</strong>
          </Typography>
        </>
      );
    }

    return (
      <Box sx={{ maxHeight: '600px', overflow: 'auto', pt: '12px' }}>
        {modulesConfig.map((d) => {
          return (
            <Content
              key={d.module}
              {...d}
              search={search}
              resetSearch={resetSearch}
              closeSearch={closeSearch}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <Box
        className={`global-search-bar ${classes.root}`}
        ref={rootRef}
        aria-describedby={id}
        sx={{ margin: mobileMatches ? '0 8px' : 0 }}
      >
        <TextField
          sx={{ minWidth: '100%', width: 'unset' }}
          type='text'
          placeholder='Search...'
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            if (e.target.value && !open) {
              setOpen(true);
            }
            updateSearch(e.target.value);
          }}
          onFocus={(): void => {
            if (search && !open) {
              setOpen(true);
            }
          }}
          value={search}
          InputProps={{
            startAdornment: <SVGIcon height='20' width='20' stroke='#737373' icon={SearchIcon} />,
            ref: inputRef,
            autoComplete: 'off',
            endAdornment: search ? (
              <SVGIcon
                icon={CloseIcon}
                stroke='#737373'
                width='20'
                height='20'
                onClick={(): void => {
                  navigate(pathname);
                  updateSearch('');
                }}
              />
            ) : (
              ''
            ),
          }}
        />
      </Box>
      <Popper
        id={id}
        open={open && !!search && !!rootRef.current}
        anchorEl={rootRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
        sx={{
          top: '10px !important',
          zIndex: 9,
        }}
      >
        {({ TransitionProps, placement }): JSX.Element => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              sx={{
                width: getPopoverWidth,
                borderRadius: '6px',
                border: '1px solid #A3A3A3',
                padding: '0',
                overflow: 'hidden',

                paddingBottom:
                  // eslint-disable-next-line no-nested-ternary
                  !isGlobalSearch && !mobileMatches ? 0 : mobileMatches ? '16px' : '12px',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box>{getComponent()}</Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default SearchBar;
