import * as React from 'react';

import { Box, List } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LeftIcon } from 'assets/images/tabler-icon/arrow-left-box.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';

import { SETTINGS_SIDE_NAV_LINKS } from './config';
import NavLinkSetting from './navLinkSetting';
import { useStyle } from './style';

interface SettingsSidenavProps {
  onClose: (e: React.FormEvent<HTMLElement>) => void;
}

function SettingsSidenav({ onClose }: SettingsSidenavProps): JSX.Element {
  const { classes } = useStyle();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <Box className={classes.backButton} onClick={onClose}>
          <SVGIcon icon={LeftIcon} stroke='#737373' width='12px' height='12px' />
        </Box>
        {t('side_nav.settings')}
      </Box>

      <List
        sx={{ width: '100%', maxWidth: 360, pt: 0, pb: 0, bgcolor: 'background.paper' }}
        component='nav'
        className={classes.sideNavList}
      >
        {SETTINGS_SIDE_NAV_LINKS.map((sideNav) => {
          const { label } = sideNav;
          return <NavLinkSetting key={label} {...sideNav} />;
        })}
      </List>
    </Box>
  );
}

export default SettingsSidenav;
