import { IDirtyFields, dirtyValues, addUrlId } from 'utils/commonUtils';

import { APIS } from '../../constants/api.constants';
import { BaseApi } from '../common/base-api';
import { processResponse } from '../common/common';
import { IAddAssetPayload, IAssetDetails } from './asset.modal';

const addOrUpdateAsset = async (
  payload: Partial<IAddAssetPayload>,
  id: string | undefined,
  dirtyFields: IDirtyFields,
): Promise<Error | null> => {
  let data = payload;
  if (id) {
    data = dirtyValues(dirtyFields, payload);
  }

  if (!data.operatorId && !id) {
    // it's an Add Asset event
    delete data.operatorId;
  }

  delete data.model;
  delete data.makeId;

  const api = id ? `${APIS.ASSETS.UPDATE_ASSET}${id}` : APIS.ASSETS.ADD_ASSET;
  const response = await BaseApi[id ? 'patch' : 'post'](api, data);
  return processResponse<null>(response);
};

const getJobDetailsByExternalId = async (externalId: string): Promise<Error | IAssetDetails> => {
  const api = addUrlId(APIS.ASSETS.GET_ASSET, externalId);
  const response = await BaseApi.get(api, {
    params: {
      externalId,
    },
  });
  return processResponse<IAssetDetails>(response);
};

export { addOrUpdateAsset, getJobDetailsByExternalId };
