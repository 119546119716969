import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ActionType, ModulesType } from 'services/settings-service/settings.modal';
import { useAppSelector } from 'store/hooks';
import { getUserPermission } from 'store/reducers/userSlice';

interface UsePermissionReturnType {
  isPermissionAvailable: (permission: string) => boolean;
  isPermissionAvailableBasedOnURL: () => { isPermitted: boolean; module: string };
}

function usePermission(): UsePermissionReturnType {
  const permissions = useAppSelector(getUserPermission);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const urlMapping = useMemo(
    () => ({
      '/assets': { key: `${ActionType.read}:${ModulesType.assets}`, module: t('asset') },
      '/settings/organisations': {
        key: `${ActionType.read}:${ModulesType.organisations}`,
        module: t('organisation'),
      },
      '/locations': {
        key: `${ActionType.read}:${ModulesType.organisation_locations}`,
        module: t('location'),
      },
      '/settings/users': { key: `${ActionType.read}:${ModulesType.users}`, module: t('User') },
    }),
    [t],
  );

  const isPermissionAvailable = useCallback(
    (permission: string): boolean => {
      return permissions?.indexOf(permission) !== -1;
    },
    [permissions],
  );

  const isPermissionAvailableBasedOnURL = useCallback((): {
    isPermitted: boolean;
    module: string;
  } => {
    const urlMappingString = Object.keys(urlMapping);

    let module = '';

    const isPermitted = urlMappingString.every((path) => {
      if (pathname.indexOf(path) !== -1) {
        const { key, module: moduleText } = urlMapping[path as keyof typeof urlMapping];
        module = moduleText;
        return permissions?.indexOf(key) !== -1;
      }

      return true;
    });

    return { isPermitted, module };
  }, [permissions, pathname, urlMapping]);

  return { isPermissionAvailable, isPermissionAvailableBasedOnURL };
}

export default usePermission;
