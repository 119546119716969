import React, { useState, useRef, useEffect } from 'react';

import { Typography, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LeftBoxIcon } from 'assets/images/tabler-icon/arrow-left-box.svg';
import { ReactComponent as CloseIcon } from 'assets/images/tabler-icon/close.svg';
import CustomModal from 'common/components/custom-modal/CustomModal';
import QrScanner from 'common/components/mobile/qr-scanner/QrScanner';
import { SnackBarType } from 'common/components/snackbar/custom-snackbar';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import ROUTES from 'constants/routes';
import useToaster from 'hooks/useToaster';
import { IAssetDetails } from 'services/asset-service/asset.modal';
import { getJobDetailsByExternalId } from 'services/asset-service/asset.service';
import { IListApiResponse } from 'services/common/common.modal';
import { remCalc } from 'theme/utils';
import { addUrlId } from 'utils/commonUtils';

import ManualAssetScanner from './ManualAssetScanner/ManualAssetScanner';

const { ASSET_DETAILS } = ROUTES.USER.ASSETS;

export interface IAssetScannerProps {
  open: boolean;
  updateModal: (status: boolean) => void;
}

export enum AssetScannerType {
  Manual = 'Manual',
  Scanner = 'Scanner',
}

function AssetScanner({ open, updateModal }: IAssetScannerProps): JSX.Element {
  const { t } = useTranslation('assets');
  const navigate = useNavigate();
  const [loading, updateLoading] = useState<boolean>(false);
  const { showToaster } = useToaster();

  const [type, updateType] = useState(AssetScannerType.Scanner);
  const scannerRef = useRef<{ startScanner: () => void; destroyScanner: () => void }>();
  const handleQrScanner = (externalId: string): void => {
    let payload = externalId;
    if (payload.match(/^0{10}/)) {
      payload = payload.replace(/^0{10}/, '');
    }

    updateLoading(true);
    getJobDetailsByExternalId(payload)
      .then((response) => {
        const { data } = response as unknown as IListApiResponse<IAssetDetails[]>;
        if (data.length) {
          const [{ assetId }] = data;
          updateModal(false);
          navigate(addUrlId(`${ASSET_DETAILS}`, assetId));
          updateLoading(false);
        } else {
          showToaster('Asset ID Not Found', undefined, SnackBarType.error);
          scannerRef?.current?.startScanner();
          updateLoading(false);
        }
      })
      .catch(() => {
        updateLoading(false);
      });
  };

  useEffect(() => {
    if (scannerRef && !open) {
      scannerRef?.current?.destroyScanner();
    }
  }, [open, scannerRef]);

  useEffect(() => {
    if (open) {
      updateType(AssetScannerType.Scanner);
    }
  }, [open, updateType]);

  const handleManualAssetScanner = (): void => {
    scannerRef?.current?.destroyScanner();
    updateType(AssetScannerType.Manual);
  };

  const handleQrScannerLink = (): void => {
    updateType(AssetScannerType.Scanner);
  };

  const getTitle = (): JSX.Element => {
    return (
      <Stack direction='row' alignItems='center' gap='12px'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #D6D6D6',
            borderRadius: '4px',
            height: '32px',
            width: '32px',
            cursor: 'pointer',
          }}
          onClick={(): void => {
            if (type === AssetScannerType.Manual) {
              updateType(AssetScannerType.Scanner);
            } else {
              if (scannerRef) {
                scannerRef?.current?.destroyScanner();
              }
              setTimeout(() => {
                updateModal(false);
              }, 0);

              updateType(AssetScannerType.Scanner);
            }
          }}
        >
          <SVGIcon width='16px' height='16px' stroke='#737373' icon={LeftBoxIcon} />
        </Box>
        {t(`common.${type === AssetScannerType.Scanner ? 'scan_asset' : 'manual_entry'}`, {
          ns: 'translation',
        })}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #D6D6D6',
            borderRadius: '4px',
            height: '32px',
            width: '32px',
            ml: 'auto',
          }}
          onClick={(): void => {
            if (scannerRef) {
              scannerRef?.current?.destroyScanner();
            }
            setTimeout(() => {
              updateModal(false);
            }, 0);
          }}
        >
          <SVGIcon width='16px' height='16px' stroke='#737373' icon={CloseIcon} />
        </Box>
      </Stack>
    );
  };

  return (
    <CustomModal
      open={open}
      title={getTitle()}
      maxWidth='sm'
      sx={{ p: 0 }}
      titleSx={{ p: '16px' }}
      loader={loading}
      hideRequiredLabel
    >
      {type === AssetScannerType.Manual ? (
        <ManualAssetScanner onChange={handleQrScanner} handleQrScannerLink={handleQrScannerLink} />
      ) : (
        <Box sx={{ height: '100%', position: 'relative', width: '100%', overflow: 'hidden' }}>
          <Typography
            sx={{
              fontSize: remCalc(24),
              position: 'absolute',
              top: '10%',
              left: '50%',
              transform: 'translateX(-50%)',
              color: '#fff',
              zIndex: 9,
              width: '100%',
              textAlign: 'center',
            }}
          >
            {t('details.scan_title', { ns: 'assets' })}
          </Typography>
          <QrScanner ref={scannerRef} loading={loading} onChange={handleQrScanner} />
          <Typography
            sx={{
              fontSize: remCalc(14),
              position: 'absolute',
              bottom: '10%',
              left: '50%',
              transform: 'translateX(-50%)',
              color: '#fff',
              zIndex: 9,
              display: 'flex',
              gap: '5px',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={handleManualAssetScanner}
          >
            QR not working?{' '}
            <Box sx={{ textDecoration: 'underline', textUnderlineOffset: '5px' }}>
              Enter Manually
            </Box>
          </Typography>
        </Box>
      )}
    </CustomModal>
  );
}

export default AssetScanner;
