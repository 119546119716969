import React, { useState, useEffect } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { ISideNavLink } from 'constants/app.drawer.constants';
import { useAppDispatch } from 'store/hooks';
import { setIsDrawerOpen } from 'store/reducers/appDrawerSlice';
import { mobileAndTabletCheck } from 'utils/commonUtils';

function SideNavLink({ label, path, child = [], icon }: ISideNavLink): JSX.Element {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isMobile = mobileAndTabletCheck();
  const handleCloseDrawer = (): void => {
    if (isMobile) {
      dispatch(setIsDrawerOpen(false));
    }
  };

  useEffect(() => {
    if (pathname.includes(path)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [pathname, setOpen, path]);

  const handleClick = (): void => {
    setOpen(!open);
  };
  const labelText = t(label);
  if (!child || !child.length) {
    return (
      <NavLink
        to={path}
        key={label}
        className={({ isActive }): string => (isActive ? 'nav-active' : '')}
        onClick={handleCloseDrawer}
      >
        <ListItemButton className='side-nav-item'>
          {icon && <SVGIcon icon={icon} fontSize='small' />}
          <ListItemText primary={labelText} className='nav-link-text' />
        </ListItemButton>
      </NavLink>
    );
  }
  return (
    <>
      <ListItemButton onClick={handleClick} className='side-nav-item'>
        <ListItemText primary={labelText} className='nav-link-text' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {child?.map(({ label: childLabel, path: childPath }): JSX.Element => {
            return (
              <NavLink
                to={childPath}
                key={childLabel}
                className={({ isActive }): string => (isActive ? 'nav-active' : '')}
                onClick={handleCloseDrawer}
              >
                <ListItemButton sx={{ pl: 4 }} className='side-nav-item'>
                  <ListItemText primary={t(childLabel)} className='nav-link-text' />
                </ListItemButton>
              </NavLink>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export default SideNavLink;
