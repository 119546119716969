import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: '#000000',
  },
  camDropdown: {
    alignSelf: 'end',
    marginBottom: '10px',
  },
  camContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1px solid #fff',
    [theme.breakpoints.up('md')]: {
      width: '500px',
      height: '500px',
    },
  },
}));

export default useStyles;
