import { Box, Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsDrawerOpen, setIsDrawerOpen } from 'store/reducers/appDrawerSlice';
import { setIsScannerOpen, selectIsScannerOpen } from 'store/reducers/scannerSlice';

import AssetScanner from '../mobile/asset-scanner/AssetScanner';
import { DRAWER_WIDTH } from '../styled/MainContentWrapper';
import AppDrawerContent from './AppDrawerContent';

interface AppDrawerProps {
  mobileMatches: boolean;
}

function AppDrawer({ mobileMatches }: AppDrawerProps): JSX.Element {
  const theme = useTheme();
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const isScannerOpen = useAppSelector(selectIsScannerOpen);
  const container = window !== undefined ? (): Element | null => window.document.body : null;
  const dispatch = useAppDispatch();

  const handleDrawerOnClose = (): void => {
    dispatch(setIsDrawerOpen(false));
  };

  return (
    <Box
      component='nav'
      sx={{
        width: { md: DRAWER_WIDTH },
        flexShrink: { md: 0 },
      }}
      aria-label='mailbox folders'
    >
      <AssetScanner
        open={isScannerOpen}
        updateModal={(status: boolean): void => {
          dispatch(setIsScannerOpen(status));
        }}
      />
      {mobileMatches && (
        <Drawer
          container={container}
          variant='temporary'
          open={isDrawerOpen}
          onClose={handleDrawerOnClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              backgroundColor: '#ffffff',
              boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.05)',
            },
          }}
        >
          <AppDrawerContent />
        </Drawer>
      )}

      {!mobileMatches && (
        <Drawer
          variant='permanent'
          container={container}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              backgroundColor: '#ffffff',
              overflowX: 'hidden',
              top: '56px',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.05)',
            },
          }}
          PaperProps={{
            elevation: 0,
          }}
          open={isDrawerOpen}
        >
          <AppDrawerContent />
        </Drawer>
      )}
    </Box>
  );
}

export default AppDrawer;
