import { Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { APIQueryConstant } from 'constants/api.constants';

// eslint-disable-next-line import/no-cycle
import { IContentProps } from './Content';
import { useStyle } from './style';

interface IWrapperProps
  extends Pick<
    IContentProps,
    'listRoute' | 'detailsRoute' | 'resetSearch' | 'search' | 'closeSearch'
  > {
  children: JSX.Element | JSX.Element[];
}

export function Wrapper({
  children,
  listRoute,
  detailsRoute,
  resetSearch,
  search,
  closeSearch,
}: IWrapperProps): JSX.Element {
  const { classes } = useStyle();
  const navigate = useNavigate();

  return (
    <Box
      className={classes.resultContent}
      onClick={(): void => {
        if (detailsRoute) {
          resetSearch();
        } else {
          closeSearch();
        }

        navigate(`${detailsRoute ?? `${listRoute}?${APIQueryConstant.searchQuery}=${search}`}`);
      }}
    >
      <Box className={classes.moduleData}>{children}</Box>
    </Box>
  );
}

export function RenderValue({
  children,
  length,
  index,
}: {
  children: JSX.Element | string | number | null;
  length: number;
  index: number;
}): JSX.Element {
  const { classes } = useStyle();
  return (
    <>
      <Typography>{children}</Typography>
      {length !== index + 1 && <Box className={classes.roundIcon} />}
    </>
  );
}
