/* eslint-disable quotes */
import React from 'react';

import { Box, Stack, Link, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NavLink } from 'react-router-dom';

import { ReactComponent as SideNavIcon } from 'assets/images/tabler-icon/sidenav.svg';
import AppProfileInfo from 'common/components/app-drawer/AppProfileInfo';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import ROUTES from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsDrawerOpen, setIsDrawerOpen } from 'store/reducers/appDrawerSlice';
import { PRODUCT_LOGO, PRODUCT_NAME } from 'utils/commonConstant';

import SearchBar from '../search-bar/SearchBar';
import OrgSelector from './org-selector/OrgSelector';
import { useStyle } from './style';

function Navbar(): JSX.Element | null {
  const { classes } = useStyle();
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const mobileMatches = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const handleDrawerOpen = (): void => {
    dispatch(setIsDrawerOpen(true));
  };

  return (
    <Box className={classes.root}>
      {mobileMatches && (
        <Box className={classes.menuHeaderSection}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            sx={{
              ...(isDrawerOpen && { display: 'none' }),
              ...(mobileMatches && { display: 'flex' }),
              p: '0 12px',
            }}
          >
            <SVGIcon icon={SideNavIcon} stroke='#fff' />
          </IconButton>
        </Box>
      )}
      {!mobileMatches && (
        <Stack direction='row' alignItems='center'>
          <Box
            className={`nav-title ${classes.titleContent}`}
            to={ROUTES.USER.OVERVIEW}
            component={NavLink}
          >
            <Stack direction='row' alignItems='center'>
              <img
                src={PRODUCT_LOGO}
                alt={PRODUCT_NAME}
                style={{
                  width: '32px',
                  height: '32px',
                  border: '1px solid #F5F5F5',
                  borderRadius: '6px',
                }}
              />
            </Stack>
          </Box>
          <OrgSelector />
        </Stack>
      )}

      <SearchBar />
      <Box sx={{ display: 'flex' }}>
        {/* //TODO need to add in ENV */}
        <Link target='_blank' href='https://help.armada.ridezoomo.com/' className={classes.help}>
          ?
        </Link>
        <AppProfileInfo />
      </Box>
    </Box>
  );
}

export default Navbar;
