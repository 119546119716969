import React from 'react';

import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowBack } from 'assets/images/icons/arrow-back.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { APIQueryConstant } from 'constants/api.constants';
import { remCalc } from 'theme/utils';

import { modulesConfig } from './config';

interface IMobileContentProps {
  search: string;
  closeSearch: () => void;
}

function MobileContent({ search, closeSearch }: IMobileContentProps): JSX.Element | null {
  const navigate = useNavigate();

  return (
    <Box sx={{ p: '16px', pb: 0 }}>
      {modulesConfig.map(({ module, listRoute, mobileView = true }) => {
        if (!mobileView) {
          return null;
        }
        return (
          <Box
            key={module}
            sx={{
              fontSize: remCalc(12),
              color: '#737373',
              mb: '12px',
              '&:last-child': { mb: 0 },
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={(): void => {
              closeSearch();
              navigate(`${listRoute}?${APIQueryConstant.searchQuery}=${search}`);
            }}
          >
            <Box>
              Search &nbsp;<strong>{search}</strong>&nbsp;in&nbsp;
              <strong style={{ textTransform: 'uppercase' }}>{module}</strong>
            </Box>

            <SVGIcon
              icon={ArrowBack}
              fontSize='small'
              width='12px'
              height='12px'
              stroke='#000000'
              style={{ marginLeft: 'auto', transform: 'rotate(180deg)' }}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default React.memo(MobileContent);
