import { AssetStatus } from 'services/asset-service/asset.modal';

// eslint-disable-next-line import/no-cycle
import { AssetStatusIcon } from './AssetStatusBadge';

export const statusColorMapping = {
  [AssetStatus.active]: '#17B26A',

  [AssetStatus.inActive]: '#F79009',

  [AssetStatus.decommissioned]: '#F04438',
};

export const statusOptions = [
  {
    value: AssetStatus.active,
    label: 'Active',
    icon: <AssetStatusIcon status={AssetStatus.active} />,
  },
  {
    value: AssetStatus.inActive,
    label: 'Inactive',
    icon: <AssetStatusIcon status={AssetStatus.inActive} />,
  },
  {
    value: AssetStatus.decommissioned,
    label: 'Decommissioned',
    icon: <AssetStatusIcon status={AssetStatus.decommissioned} />,
  },
];

export const getStatusLabel = (status: AssetStatus): string =>
  statusOptions.find(({ value }) => value === status)?.label ?? '-';
